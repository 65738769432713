import React from 'react';
import Grid from '@material-ui/core/Grid';
import videoThumb from '../../public/img/video.png';
import videoplayIcon from '../../public/img/videoPlay.svg';
import podcastIcon from '../../public/img/podcast.svg';
import playlistIcon from '../../public/img/playlist.png';
import { HomeService } from '../home/Service/homeService';

const Thumbnail = (props) => {
    const validURL = (str) => {
        try {
            new URL(str);
            return true;
        }
        catch (err) {
            return false;
        }

    }

    const [thumbnailImage, SetThumbnailImage] = React.useState('');
    React.useEffect(() => {
        let service = new HomeService();
        let content_thumbnail = props.content.user_thumbnail;
        if (props.content.content_type === 'playlist') {

            if (!validURL(`'${props.content.thumbnail}'`)) {

                content_thumbnail = props.content.thumbnail;
            }
        }
        if (content_thumbnail) {
            if (content_thumbnail.length > 0) {
                service.GetImageFromURL(content_thumbnail).then((data) => {
                    if (data) {
                        SetThumbnailImage(data);
                    }
                })
            } else {
                if (props.content.thumbnail) {
                    SetThumbnailImage(props.content.thumbnail);
                }
            }
        } else {
            if (props.content.thumbnail) {
                SetThumbnailImage(props.content.thumbnail);
            }
        }
    }, [props.content.user_thumbnail, props.content.thumbnail, props.content.content_type]);
    return (
        <>
            <Grid className='sCoverInner-Content'>
                {props.content.content_type === 'video' &&
                    // <object type='image/svg+xml' data={videoplayIcon} className={'svgIcon-content video'} alt='icon'></object>
                    <img src={videoplayIcon} className={'svgIcon-content video'} alt='icon' />
                }
                {props.content.content_type === 'playlist' &&
                    <img src={playlistIcon} className={'svgIcon-content playlist'} alt='icon' />
                }
                {props.content.content_type === 'podcast' &&
                    // <object type='image/svg+xml' data={podcastIcon} className={'svgIcon-content podcast'} alt='icon'></object>
                    <img src={podcastIcon} className={'svgIcon-content podcast'} alt='icon' />
                }
            </Grid>
            {thumbnailImage && <img width="120" src={thumbnailImage.length > 0 ? thumbnailImage : videoThumb} className={props?.page == 'related-content' ? 'related-content-image simg-lg'
                : props.page == 'carousel-slider' ? 'carousel-img simg-lg'
                    : props.page == 'content-list' ? 'content-list simg-lg' : 'simg-lg'} alt='img' />}
        </>
    )
}
export default Thumbnail