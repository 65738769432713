import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from '../../../layout/breadcrumb';
import Modal from '@material-ui/core/Modal';
import { useNavigate } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import './country-profile.css';
import { CountryService } from '../Services/country-service';

import CountryDetails from '../components/CountryDetails';
import Customization from '../components/Customization';
import Category from '../components/Category';
import Banners from '../components/Banners';
import FixedMenu from '../components/FixedMenu';
import Library from '../components/Library';
import Profession from '../components/Profession';
import Speciality from '../components/Speciality';
import Alert from '@material-ui/lab/Alert';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const CountryProfile = (props) => {
    const navigate = useNavigate();
    let uri = window.location.href;
    let affiliate_id = uri.split('/')[uri.split('/').length - 1];
    let countryService = new CountryService();

    const [professionsDict, SetProfessionsDict] = React.useState({});
    const [specialities, SetSpecialities] = React.useState([]);

    const [therapeuticAreas, SetTherapeuticAreas] = React.useState([]);
    const [therapeuticAreaMap, SetTherapeuticAreaMap] = React.useState({});
    const [brands, SetBrands] = React.useState([]);
    const [indications, SetIndications] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [selectedCategories, SetSelectedCategories] = React.useState([]);
    const [selectedTAIds, SetSelectedTAIds] = React.useState([]);
    const [selectedCat1Ids, SetSelectedCat1Ids] = React.useState([]);
    const [selectedCat2Ids, SetSelectedCat2Ids] = React.useState([]);
    const [chkOpen, SetChkOpen] = React.useState([]);
    const [piInfo, SetPIInfo] = React.useState([]);
    const [bannerMap, SetBannerMap] = React.useState({});
    const [dictionaryOrder, SetDictionaryOrder] = React.useState([]);
    const [fixedMenus, SetFixedMenus] = React.useState([]);
    const [bannersDict, SetBannersDict] = React.useState({});
    const [taBannerOrderDict, SettaBannerOrderDict] = React.useState({});
    const [libraryActive, SetlibraryActive] = React.useState(1);
    const [libraryArray, SetlibraryArray] = React.useState([]);
    const [deletedlibraryId, setdeletedlibraryId] = React.useState([]);
    const [country_logo_affiliate, setcountry_logo_affiliate] = React.useState('');
    const [tatoogle, Settatoogle] = React.useState(false);
    const [mobilebannersDict, SetMobilebannersDict] = React.useState({});
    const [mobiledictionaryOrder, SetMobiledictionaryOrder] = React.useState([]);
    const [mobilebannersMap, SetMobilebannersMap] = React.useState({});
    const [mobiletaBannerOrderDict, SetMobiletaBannerOrderDict] = React.useState({});
    const [urltextPopup, SeturltextPopup] = React.useState('');
    const [urltextPopupBool, SeturltextPopupBool] = React.useState(false);
    const [countryUrlValidationDict, SetcountryUrlValidationDict] = React.useState({});
    const [countryBannerValidationDict, SetcountryBannerValidationDict] = React.useState({});
    const [countryBannerValidationBool, SetcountryBannerValidationBool] = React.useState(false);
    const [country_logo_preview, setcountry_logo_preview] = React.useState(false);
    const [isDisabled,setIsDisabled] = React.useState(false);
    const [state, setState] = React.useState({
        country_logo: '',
        country_name: '',
        domain_url: '',
        speciality_customization: false,
        upnext: false,
        is_like: false,
        on24: false,
        footer: false,
        show_more:false,
        mini_player: true
    });
    const handleChange = (field, value) => {
        setState({ ...state, [field]: value });
    };
    const handleDelete = () => {
        countryService.DeleteCountry(affiliate_id).then((data) => {
            deletePopupClose();
            navigate('/country-management');
        })
    }
    const handleSubmit = () => {
        let model = {
            mobile_order_dictionary: mobilebannersMap,
            mobile_banner_ids: mobiledictionaryOrder,
            mobile_ta_bannerid_order: mobiletaBannerOrderDict,
            country_code: affiliate_id,
            country_logo: country_logo_affiliate,
            country_name: state.country_name,
            domain_url: state.domain_url,
            tatoogle: tatoogle,
            speciality_customization: state.speciality_customization,
            upnext: state.upnext,
            is_like: state.is_like,
            show_more: state.show_more,
            mini_player: state.mini_player,
            footer: state.footer,
            specialities: specialities,
            professions: professionsDict,
            fixed_menus: fixedMenus,
            banners: bannerMap,// bannersDict,
            pi_info: piInfo,
            banner_ids: dictionaryOrder,
            order_dictionary: {},//bannerMap,
            country_id: affiliate_id,
            ta_ids: [...new Set(selectedTAIds)],
            cat1_ids: [...new Set(selectedCat1Ids)],
            cat2_ids: [...new Set(selectedCat2Ids)],
            ta_bannerid_order: taBannerOrderDict,
            library_active: libraryActive,
            library: libraryArray,
            deletedlibraryId: deletedlibraryId
        }
        let validationBannerUrl = false;
        let validationBannerText = "";


        if (libraryActive > 1 && libraryArray.length > 0) {
            libraryArray.forEach(lib => {
                if (lib.thumbnail_url.length === 0) {
                    SeturltextPopupBool(true);
                    SeturltextPopup("Library: " + lib.library_name + " has no image");
                    return;
                }
            })
        }
        for (const key in countryUrlValidationDict) {
            if (countryUrlValidationDict[key].length > 0) {
                SeturltextPopupBool(true);
                SeturltextPopup("Url in carousel is not valid for " + countryUrlValidationDict[key] + "!")
                return;
            }
        }
        for (const key in model.banners) {
            for (const k in model.banners[key]) {
                if (model.banners[key][k] && model.banners[key][k]['url'] && model.banners[key][k]['url'] != "") {
                    if (!isValidHttpUrl(model.banners[key][k]['url'])) {

                        validationBannerUrl = true;
                    }
                }
            }
        }
        model.order_dictionary = BannerMapDestroyer();

        countryService.UpdateCountry(model).then((data) => {
            navigate('/country-management');
        })
        setIsDisabled(true)
    }

    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }
    // Get Country Info
    const GetCountryInfo = () => {
        countryService.GetAffiliateByQuery({ country_code: affiliate_id }).then((data) => {
            if (data.data.length > 0) {
                let country = data.data[0];
                setState({
                    ...state,
                    country_logo: country.avatar_attachment_id,
                    country_name: country.country_name,
                    domain_url: country.domain_url,
                    speciality_customization: country.speciality_customization,
                    upnext: country.upnext,
                    is_like: country.is_like,
                    show_more:country.show_more,
                    mini_player: country.mini_player,
                    footer: country.footer
                });
                setcountry_logo_affiliate(country.avatar_attachment_id)
                setcountry_logo_preview(true);
                if (country.therapeutic_area_ids) {
                    SetSelectedTAIds(country.therapeutic_area_ids);
                }
                if (country.category_1_ids) {
                    SetSelectedCat1Ids(country.category_1_ids);
                }
                if (country.category_2_ids) {
                    SetSelectedCat2Ids(country.category_2_ids);
                }
                if (country.fixed_filter_ids) {
                    SetFixedMenus(country.fixed_filter_ids);
                }
                if(country.pi_info) {
                    SetPIInfo(country.pi_info);
                }

                let chkOpenArr = [];
                if (country.category_1_ids.length > 0) {
                    for (let i = 0; country.category_1_ids.length > i; i++) {
                        let brand_id = country.category_1_ids[i];
                        chkOpenArr[brand_id] = true;
                    }
                }
                SetChkOpen(chkOpenArr);

                // Prepare Audiance Profession Mapping Array and Get Professions
                let professionArr = {};
                let audience = [
                    "HCP Speaker",
                    "Physician Non Speaker",
                    "Pharmacist",
                    "Nurse",
                    "Others"
                ];
                for (let i = 0; audience.length > i; i++) {
                    let list = [];
                    professionArr[audience[i]] = list;
                }
                GetCountryProfessions(professionArr);
                GetCountrySpecialities();
                //GetCountryCategoriesPIInfo();
                SetlibraryActive(country.library_active)
                // tatoogle = {tatoogle}
                Settatoogle(country.ta_toogle)
            }
        }).catch((err) => {  })
    }
    // Get Country Professions
    const GetCountryProfessions = (professionArr) => {
        countryService.GetCountryProfessions(affiliate_id).then((data) => {
            let professions = data.data;
            if (professions.length > 0) {
                let initProfessionsDict = professionArr;
                for (let i = 0; professions.length > i; i++) {
                    if (typeof initProfessionsDict[professions[i].profession_key] != "undefined") {
                        initProfessionsDict[professions[i].profession_key].push(professions[i]);
                    }
                }
                SetProfessionsDict(initProfessionsDict);
            } else { SetProfessionsDict(professionArr); }
        }).catch((err) => {  })
    }
    // Get Country Specialities
    const GetCountrySpecialities = () => {
        countryService.GetCountrySpecialities(affiliate_id).then((data) => {
            let specialities = data.data;
            if (specialities.length > 0) {
                SetSpecialities(specialities);
            }
        }).catch((err) => { })
    }
    // Get Country Categories PI Info
    const GetCountryCategoriesPIInfo = () => {
        countryService.GetCountryCategoriesPIInfo(affiliate_id).then((data) => {
            let piInfo = data.data;
            let piInfoArr = [];
            if (piInfo.length > 0) {
                for (let i = 0; piInfo.length > i; i++) {
                    piInfoArr.push({
                        therapeutic_area_id: piInfo[i].therapeutic_area_id,
                        brand_id: piInfo[i].brand_id,
                        pi_url: piInfo[i].pi_url,
                        is_warning: piInfo[i].is_warning,
                    });
                }
            }
            SetPIInfo(piInfoArr);
        }).catch((err) => {  })
    }

    // Get All Therapeutic Areas
    const GetAllTherapeuticAreas = () => {
        countryService.GetAllTherapeuticAreas().then((data) => {
            let therapeutic_areas = data.data;
            if (therapeutic_areas.length > 0) {
                let dict = {}
                for (let i = 0; i < therapeutic_areas.length; i++) {
                    dict[therapeutic_areas[i].therapeutic_area_id] = therapeutic_areas[i];
                }
                SetTherapeuticAreaMap(dict);
                SetTherapeuticAreas(therapeutic_areas);
            }
        }).catch((err) => { })
    }
    // Get All Brands
    const GetAllBrands = () => {
        countryService.GetAllBrands().then((data) => {
            let brands = data.data;
            if (brands.length > 0) {
                SetBrands(brands);
            }
        }).catch((err) => {  })
    }
    // Get All Indications
    const GetAllIndications = () => {
        countryService.GetAllIndications().then((data) => {
            let indications = data.data;
            if (indications.length > 0) {
                SetIndications(indications);
            }
        }).catch((err) => {  })
    }
    const BannerMapDestroyer = () => {
        let bannerMappingObject = Object.assign({}, bannerMap);
        for (let i = 0; i < fixedMenus.length; i++) {
            let dict = bannerMappingObject[fixedMenus[i]];
            if (typeof dict !== "undefined") {
                for (let j = 0; j < Object.keys(dict).length; j++) {
                    bannerMappingObject[fixedMenus[i]][Object.keys(
                        bannerMappingObject[fixedMenus[i]])[j]]['displayfile'] = '';
                }
            }
        }
        return bannerMappingObject;

    }
    React.useEffect(() => {
        GetCountryInfo();
        GetAllTherapeuticAreas();
        GetAllBrands();
        GetAllIndications();
        let service = new CountryService();
        let affiliate_id = uri.split('/')[uri.split('/').length - 1];
        service.GetCountryBanner(affiliate_id).then((data) => {
            if (Array.isArray(data)) {
                if (data[0] && data[0].order_dictionary) {
                    service.GenerateS3Files(Object.assign({}, data[0].order_dictionary), SetBannerMap,
                        data[0].ta_ids).then((generatedDate) => {
                        })
                    service.GenerateS3Files(Object.assign({}, data[0].mobile_order_dictionary), SetMobilebannersMap,
                        data[0].ta_ids).then((generatedDate) => {
                        })
                    SetMobiledictionaryOrder(data[0].mobile_banner_ids)
                    SetDictionaryOrder(data[0].banner_ids);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let countryId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'Country Management', path: '/country-management' },
        { value: 'Country Profile', path: '/country-profile/' + countryId },
    ];

    // Handle Confirmation Popup
    const [openDelModal, setOpenDelModal] = React.useState(false);
    const deletePopupClose = () => { setOpenDelModal(false); }
    const deletePopupOpen = () => { setOpenDelModal(true); }

    const closeUrlPopup = () => {
        SeturltextPopupBool(false)
    }
    const closeBannerPopup = () => {
        SetcountryBannerValidationBool(false)
    }

    return (
        <>
            <Modal open={openDelModal} onClose={deletePopupClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={deletePopupClose}></button>
                    </Grid>
                    <Grid className='box'>
                        Country <b>{state.country_name}</b> will be deleted, are you sure?
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" className="modalBtn" onClick={deletePopupClose}>Cancel</button>
                        <button type="button" className="modalBtn" onClick={handleDelete}>Delete</button>
                    </Grid>
                </Grid>
            </Modal>
            <Modal open={urltextPopupBool} onClose={closeUrlPopup}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={closeUrlPopup}></button>
                    </Grid>
                    <Grid className='box'>
                        <Alert severity="warning">{urltextPopup}</Alert>

                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" className="modalBtn" onClick={closeUrlPopup}>Close</button>
                    </Grid>
                </Grid>
            </Modal>

            <Modal open={countryBannerValidationBool} onClose={closeBannerPopup}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={closeBannerPopup}></button>
                    </Grid>
                    <Grid className='box'>
                        {/* <Alert severity="warning">{urltextPopup}</Alert> */}
                        <Grid className='attenSec attenSecvalidationImage'>
                            <span className='attenIcnSec'>
                                <ReportProblemIcon className='attenIcn' />
                                Attention
                            </span>
                            <p className='attenTxt'>
                                The image that you uploaded isn't in the correct proportions and might be unwanted-trimmed. We suggest reviewing the image height and width
                            </p>
                        </Grid>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" className="modalBtn" onClick={closeBannerPopup}>Close</button>
                    </Grid>
                </Grid>
            </Modal>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <Grid className="main-wrp">
                <Grid container className='outer'>
                    <Grid item xs={12} lg={12} className='header'>
                        <Typography variant="h6">Country Profile</Typography>
                    </Grid>

                    <CountryDetails handleChange={handleChange} state={state} country_logo_affiliate={country_logo_affiliate} setcountry_logo_affiliate={setcountry_logo_affiliate} 
                    setcountry_logo_preview={setcountry_logo_preview}/>
                    <Banners bannersDict={bannersDict} SetBannersDict={SetBannersDict} SetDictionaryOrder={SetDictionaryOrder}
                        therapeuticAreaMap={therapeuticAreaMap} fixedMenus={fixedMenus} dictionaryOrder={dictionaryOrder}
                        SetBannerMap={SetBannerMap} bannerMap={bannerMap} SetFixedMenus={SetFixedMenus}
                        taBannerOrderDict={taBannerOrderDict} SettaBannerOrderDict={SettaBannerOrderDict}
                        mobilebannersDict={mobilebannersDict} SetMobilebannersDict={SetMobilebannersDict}
                        mobiledictionaryOrder={mobiledictionaryOrder} SetMobiledictionaryOrder={SetMobiledictionaryOrder}
                        mobilebannersMap={mobilebannersMap} SetMobilebannersMap={SetMobilebannersMap}
                        SeturltextPopup={SeturltextPopup} SeturltextPopupBool={SeturltextPopupBool}
                        mobiletaBannerOrderDict={mobiletaBannerOrderDict} SetMobiletaBannerOrderDict={SetMobiletaBannerOrderDict}
                        countryUrlValidationDict={countryUrlValidationDict} setcountryUrlValidationDict={SetcountryUrlValidationDict}
                        countryBannerValidationDict={countryBannerValidationDict} SetcountryBannerValidationDict={SetcountryBannerValidationDict} SetcountryBannerValidationBool = {SetcountryBannerValidationBool}

                    />
                    <Customization handleChange={handleChange} state={state} />
                    <Category selectedTAIds={selectedTAIds} SetSelectedTAIds={SetSelectedTAIds}
                        selectedCat1Ids={selectedCat1Ids} SetSelectedCat1Ids={SetSelectedCat1Ids}
                        selectedCat2Ids={selectedCat2Ids} SetSelectedCat2Ids={SetSelectedCat2Ids}
                        chkOpen={chkOpen} SetPIInfo={SetPIInfo} piInfo={piInfo} />
                    <FixedMenu therapeuticAreas={therapeuticAreas} fixedMenus={fixedMenus} SetFixedMenus={SetFixedMenus} />
                    <Library therapeuticAreas={therapeuticAreas} brands={brands} indications={indications} fixedMenus={fixedMenus} SetFixedMenus={SetFixedMenus} handleChange={handleChange} state={state} selectedCategories={selectedCategories}
                        SetlibraryActive={SetlibraryActive} libraryActive={libraryActive} SetlibraryArray={SetlibraryArray} libraryArray={libraryArray} setdeletedlibraryId={setdeletedlibraryId} deletedlibraryId={deletedlibraryId}
                        selectedTAIds={selectedTAIds}
                        selectedCat1Ids={selectedCat1Ids}
                        selectedCat2Ids={selectedCat2Ids}
                        tatoogle={tatoogle}
                        Settatoogle={Settatoogle}

                    />
                    <Profession professionsDict={professionsDict} SetProfessionsDict={SetProfessionsDict} />
                    <Speciality specialities={specialities} SetSpecialities={SetSpecialities} />
                    <Grid item xs={12} lg={12} className='footer'>
                        <Grid item xs={12} lg={6}>
                            <button className='cancelBtn' onClick={deletePopupOpen} >Delete</button>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <button className='submitBtn' onClick={handleSubmit} disabled={isDisabled}>Submit</button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CountryProfile;
