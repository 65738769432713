import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import './content-update.css';
import { ContentService } from '../Service/content-service';
import { useNavigate } from "react-router-dom";
import PreviewAndType from '../Components/PreviewAndType';
import ContentInputFields from '../Components/ContentInputFields';
import ContentCategory from '../Components/ContentCategory';
import VVPMDetail from '../Components/VVPMDetails';
import ContentPublish from '../Components/ContentPublish.jsx';
import ContentQuiz from '../Components/QuizDetails.jsx';
import ContentSubtitle from '../Components/ContentSubtitles.jsx';
import Audience from '../Components/Audience.jsx';
import Specialities from '../Components/Specialties.jsx';
import AddProducts from '../Components/AddProducts';
import ContentShare from '../Components/ContentShare.jsx';
import { useSelector } from 'react-redux';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import useNavigateCallbackPrompt from '../../../hooks/useNavigateCallbackPrompt.js';
import { KalturaService } from '../Service/KalturaService.js';

function cleanDescription(text) {
  return text.replace(/<[^>]*>/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();
}

function removeTags(text) {
  return text.replace('<p>', '')
    .replace('</p>', '')
    .trim();
}

const ContentUpdate = (props) => {
  let contentService = new ContentService();

  const [speakerData, setSpeakerData] = React.useState([]);
  const [selectedSpeakers, setSelectedSpeakers] = React.useState([]);
  const [shareable, setShareable] = React.useState('no');
  const [materials, SetMaterials] = React.useState([]);
  const [subtitles, SetSubtitles] = React.useState([]);
  const [title, SetTitle] = React.useState('');
  const [description, SetDescription] = React.useState('');
  const [surveyUrl, SetSurveyUrl] = React.useState('');
  const [id, SetId] = React.useState('');
  const [tags, SetTags] = React.useState([]);
  const [affiliates, SetAffiliates] = React.useState([]);
  const [affiliatesName, SetAffiliatesName] = React.useState([]);
  const [functionalArea, SetFunctionalArea] = React.useState("");
  const [categories, SetCategories] = React.useState("");
  const [secondOwner, SetSecondOwner] = React.useState('');
  const [contentType, SetContentType] = React.useState('');
  const [kaltura_id, Setkaltura_id] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [vvpmCode, SetVvpmCode] = React.useState('');
  const [content_libraries, Setcontent_libraries] = React.useState([]);
  const [created_by, Setcreated_by] = React.useState();
  const [updated_by, Setupdated_by] = React.useState();
  const [productCharacteristics, SetProductCharacteristics] = React.useState([]);
  const [selectedBrands, SetselectedBrands] = React.useState([]);
  const [selectedIndications, SetselectedIndications] = React.useState([]);
  const [selectedTherapeuticAreas, SetselectedTherapeuticAreas] = React.useState([]);
  const [selectedCategory, SetSelectedCategory] = React.useState([]);
  const [selectedSubCategory, SetSelectedSubCategory] = React.useState([]);
  const [thumbnailUrl, Setthumbnai] = React.useState('');
  const [taxTA, setTaxTA] = React.useState([]);
  const [taxBrands, setTaxBrands] = React.useState([]);
  const [taxIndication, setTaxIndication] = React.useState([]);
  const [productId, setProductId] = React.useState([]);
  const [indicationId, setIndicationId] = React.useState([]);
  const [taxBrandInd, setTaxBrandInd] = React.useState([]);
  const [VVPMFromDate, setVVPMFromDate] = React.useState("");
  const [VVPMExpirationDate, setVVPMExpirationDate] = React.useState("");
  const [VVPMDetails, SetVVPMDetails] = React.useState([]);
  const [affiliateObjModel, SetAffiliateObjModel] = React.useState({});
  const [hasRows, SetHasRows] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [ownerSecondOwnerEmail, SetOwnerSecondOwnerEmail] = React.useState([]);
  const [recordingDate, setRecordingDate] = React.useState("");
  const [statusApproved, setStatusApproved] = React.useState(false);
  let selectorValue = useSelector(state => state.session);
  const translationReduxState = useSelector(state => state.translation);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [isRoleHCP, setIsRoleHCP] = React.useState(false);
  const [isRoleInternal, setIsRoleInternal] = React.useState(false);
  const [isRolePublisher, setIsRolePublisher] = React.useState(false);
  const [isRoleAdmin, setIsRoleAdmin] = React.useState(false);
  const [affiliateToPublish, setAffiliateToPublish] = React.useState({});
  const [libraries, SetLibraries] = React.useState([]);
  const [audience, SetAudience] = React.useState([]);
  const [specialties, SetSpecialties] = React.useState([]);
  const [checkAffiliates, SetCheckAffiliates] = React.useState(false);
  const [audienceMap, SetAudienceMap] = React.useState({});
  const [specialtyMap, SetSpecialtyMap] = React.useState({});
  const [tempThumbnail, SetTempThumbnail] = React.useState('');
  const [quizId, SetQuizId] = React.useState(0);
  const [quizDetails, SetQuizDetails] = React.useState({});
  const [quizSelected, SetQuizSelected] = React.useState([]);
  const [user_id, setUser_id] = React.useState();
  const [contentlostpopup, Setcontentlostpopup] = React.useState(false);
  const [countryName, setCountryName] = React.useState();
  const [checkInitialUpdate, SetCheckInitialUpdate] = React.useState(false);
  const [countryChangePopup, setcountryChangePopup] = React.useState(false);
  const [detectChange, setdetectChange] = React.useState(false);
  const [changeCountryState, setchangeCountryState] = React.useState(false);
  const [expirationDateValidation, setExpirationDateValidation] = React.useState(false);
  const [previousStateCountryPublished, SetpreviousStateCountryPublished] = React.useState({});
  const navigate = useNavigate();
  const [contentlost, setcontentlost] = React.useState('false');
  const [isSaveButtonClicked, SetIsSaveButtonClicked] = React.useState(false);
  const [publisherCountryList, SetPublisherCountryList] = React.useState([]);
  const [vvpmAffiliateMap, SetVVPMAffiliateMap] = React.useState({});
  const [subType, SetSubType] = React.useState([]);
  const [classificationCode, SetClassificationCode] = React.useState([]);
  const [checkedCountries, setCheckedCountries] = React.useState([]);
  const [belgDutchTitle, setBelgDutchTitle] = React.useState('');
  const [belgDutchDesc, setBelgDutchDesc] = React.useState('');
  const [belgFrenchTitle, setBelgFrenchTitle] = React.useState('');
  const [belgFrenchDesc, setBelgFrenchDesc] = React.useState('');
  const [switzGermanTitle, setSwitzGermanTitle] = React.useState('');
  const [switzGermanDesc, setSwitzGermanDesc] = React.useState('');
  const [switzFrenchTitle, setSwitzFrenchTitle] = React.useState('');
  const [switzFrenchDesc, setSwitzFrenchDesc] = React.useState('');
  const [belgDutchChecked, setBelgDutchChecked] = React.useState(false);
  const [belgFrenchChecked, setBelgFrenchChecked] = React.useState(false);
  const [switzFrenchChecked, setSwitzFrenchChecked] = React.useState(false);
  const [switzGermanChecked, setSwitzGermanChecked] = React.useState(false);
  const [s3UploadCheck, SetS3UploadCheck] = React.useState(false);
  const [imageSizeCheck, SetImageSizeCheck] = React.useState(false);
  const [imageTypeCheck, SetImageTypeCheck] = React.useState(false);
  const [s3UploadErrorCheck, SetS3UploadErrorCheck] = React.useState(false);
  const [imageDimensionCheck, SetImageDimensionCheck] = React.useState(false);
  const [secondOwnerArry, setSecondOwnerArray] = React.useState([])
  const [secondOwnerVal, setSecondOwnerVal] = React.useState({});
  const [brandTaxnomy, setbrandTaxnomy] = React.useState([]);
  const [disableClicks, setDisableClicks] = React.useState(false);
  const [embedvideovisible, setEmbedVideoVisible] = React.useState(false);
  //States that are exclusive to check brand indication mapping
  const [checkbrand, setCheckBrand] = React.useState([]);
  const [checkindicaton, setCheckIndication] = React.useState([]);

  const [uploadThumbnailFilename, setUploadThumbnailFilename] = React.useState('');
  const [uploadThumbnailFilesize, setUploadThumbnailFilesize] = React.useState('')
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarType, setSnackbarType] = React.useState('');
  const [snackbarMsg, setSnackbarMsg] = React.useState('')
  const [enableFields, setEnabledFields] = React.useState(true);
  const [validationPopup, SetvalidationPopup] = React.useState(false);
  const [oldSelectedAffiliates, setOldSelectedAffiliates] = React.useState([]);
  const [alternate, setAlternate] = React.useState(false);
  const blockNevigation = useMemo(() => (contentlost && contentlost != 'false') || id === '', [contentlost, id]);
  const [ showPrompt, confirmNavigation, cancelNavigation ] = useNavigateCallbackPrompt(blockNevigation);
  let forwardStates = {
    Belgium: {
      Dutch: {
        checked: {
          get: belgDutchChecked,
          set: setBelgDutchChecked,
        },
        title: {
          get: belgDutchTitle,
          set: setBelgDutchTitle,
        },
        description: {
          get: belgDutchDesc,
          set: setBelgDutchDesc,
        }
      },
      French: {
        checked: {
          get: belgFrenchChecked,
          set: setBelgFrenchChecked,
        },
        title: {
          get: belgFrenchTitle,
          set: setBelgFrenchTitle,
        },
        description: {
          get: belgFrenchDesc,
          set: setBelgFrenchDesc,
        }
      }
    },
    Switzerland: {
      German: {
        checked: {
          get: switzGermanChecked,
          set: setSwitzGermanChecked,
        },
        title: {
          get: switzGermanTitle,
          set: setSwitzGermanTitle,
        },
        description: {
          get: switzGermanDesc,
          set: setSwitzGermanDesc,
        }
      },
      French: {
        checked: {
          get: switzFrenchChecked,
          set: setSwitzFrenchChecked,
        },
        title: {
          get: switzFrenchTitle,
          set: setSwitzFrenchTitle,
        },
        description: {
          get: switzFrenchDesc,
          set: setSwitzFrenchDesc,
        }
      }
    }
  };
  React.useEffect(() => {
    if (sessionState.sessionValue?.payload?.loginroute) {
      contentService.GetVVPMDetailsSubType().then((data) => {
        if (Array.isArray(data.data.data)) {
          let list = [];
          for (let i = 0; i < data.data.data.length; i++) {
            if (data.data.data[i].subtype__v) {
              if (!list.includes(data.data.data[i].subtype__v)) {
                list.push(data.data.data[i].subtype__v);
              }
            }
          }
          SetSubType(list);
        }
      }).catch((err) => { });
      contentService.GetVVPMDetailsClassfication().then((data) => {
        if (Array.isArray(data.data.data)) {
          let list = [];
          for (let i = 0; i < data.data.data.length; i++) {
            if (data.data.data[i].classification__v) {
              if (!list.includes(data.data.data[i].classification__v)) {
                list.push(data.data.data[i].classification__v);
              }
            }
          }
          SetClassificationCode(list);
        }
      }).catch((err) => { })
      contentService.GetSpeakersByQuery().then((data) => {
        let list = [];
        for (let i = 0; i < data.data.length; i++) {
          data.data[i].id = data.data[i].speaker_id
          list.push(data.data[i])
        }
        setSpeakerData(list);
      }).catch((err) => { });
      if (sessionState.sessionValue?.payload?.user) {
        setOwner(sessionState.sessionValue.payload.user.id);
        Setcreated_by(sessionState.sessionValue.payload.user.id);
        Setupdated_by(sessionState.sessionValue.payload.user.id);
        setUser_id(sessionState.sessionValue.payload.user.id);
      }

      if (sessionState.sessionValue.payload.affiliate.length) {
        let countryListArr = [];
        for (let i = 0; i < sessionState.sessionValue.payload.affiliate.length; i++) {
          countryListArr[sessionState.sessionValue.payload.affiliate[i].country_code] = sessionState.sessionValue.payload.affiliate[i];
        }
        SetPublisherCountryList(countryListArr)
      }

      for (let i = 0; i < sessionState.sessionValue.payload.roles.length; i++) {
        if (sessionState.sessionValue.payload.roles[i].role_name === "hcp") {

          setIsRoleHCP(true);

        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "internal") {

          setIsRoleInternal(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "publisher") {

          setIsRolePublisher(true);

        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "admin") {

          setIsRoleAdmin(true);
        }
      }

    }
  }, [sessionState]);

  React.useEffect(() => {
    if (props.thumbnailUrl) {
      Setthumbnai(props.thumbnailUrl)
    }
  }, [props.thumbnailUrl]);
  React.useEffect(() => {
    if (props.kalturaId) {
      Setkaltura_id(props.kalturaId)
    }
  }, [props.kalturaId]);

  React.useEffect(() => {
    let uri = window.location.href;
    let videoId = uri.split('/')[uri.split('/').length - 1];
    if (videoId !== "content-update") {
      SetId(videoId)
      GetContentData(props.contentData);
      window.scrollTo(0, 0);
    }
    if (!localStorage.getItem('sessionValue')) {
      localStorage.setItem('oldUrl', window.location.href);
      let uri = new URL(window.location.href);
      if (uri.pathname.includes('content-update')) {
        navigate('/sso');
      }
    }
  }, []);

  React.useEffect(() => {
    if (title !== "" && selectedBrands.length !== 0 && affiliates.length !== 0 && description !== '' && contentType !== '' && props.showPreviewOfVideo) {
      SetvalidationPopup(false);
      return;
    }
  }, [props.showPreviewOfVideo])


  const GetContentData = (data) => {
    let videos = data;
    if (videos.length > 0) {
      let video = videos[0];
      SetId(video.content_id);
      props.SetShowPreviewOfVideo(true);
      SetAffiliates(Array.isArray(video.country_codes) ? video.country_codes : []);
      contentService.GetSpeakersById(video.speakers_id).then((data) => {
        let arr = []
        for (let i = 0; i < data.data.length; i++) {
          data.data[i].id = data.data[i].speaker_id
          data.data[i].key = data.data[i].speaker_id
          data.data[i].label = data.data[i].name
          arr.push(data.data[i])
        }
        let jsondata = data.data;
        if (jsondata.length > 0) {
          setSelectedSpeakers(arr);
        }
      }).catch((err) => { });

      // multilingual_content starts
      if (video?.multilingual_content) {
        let checkedCountries = [];
        if (video?.multilingual_content?.Belgium?.Dutch?.checked) {
          checkedCountries.push('BE-Dutch')
        }
        if (video?.multilingual_content?.Belgium?.French?.checked) {
          checkedCountries.push('BE-French')
        }
        if (video?.multilingual_content?.Switzerland?.German?.checked) {
          checkedCountries.push('CH-German')
        }
        if (video?.multilingual_content?.Switzerland?.French?.checked) {
          checkedCountries.push('CH-French')
        }
        setCheckedCountries(checkedCountries);
        setBelgDutchChecked(video?.multilingual_content?.Belgium?.Dutch?.checked);
        setBelgFrenchChecked(video?.multilingual_content?.Belgium?.French?.checked);
        setSwitzGermanChecked(video?.multilingual_content?.Switzerland?.German?.checked);
        setSwitzFrenchChecked(video?.multilingual_content?.Switzerland?.French?.checked);

        setBelgDutchTitle(video?.multilingual_content?.Belgium?.Dutch?.title);
        setBelgFrenchTitle(video?.multilingual_content?.Belgium?.French?.title);
        setSwitzGermanTitle(video?.multilingual_content?.Switzerland?.German?.title);
        setSwitzFrenchTitle(video?.multilingual_content?.Switzerland?.French?.title);

        setBelgDutchDesc(video?.multilingual_content?.Belgium?.Dutch?.description);
        setBelgFrenchDesc(video?.multilingual_content?.Belgium?.French?.description);
        setSwitzGermanDesc(video?.multilingual_content?.Switzerland?.German?.description);
        setSwitzFrenchDesc(video?.multilingual_content?.Switzerland?.French?.description);
      }
      // multilingual_content ends 

      Setupdated_by(video.updated_by);
      Setcreated_by(video.created_by);
      Setcontent_libraries(Array.isArray(video.content_libraries) ? video.content_libraries : []);
      SetVvpmCode(video.vvpm_details);
      SetContentType(video.content_type);
      SetSecondOwner(video.second_owner);
      SetFunctionalArea(video.functional_area);
      SetSubtitles(Array.isArray(video.subtitles) ? video.subtitles : video.subtitles ? JSON.parse(video.subtitles) : []);
      SetTags(Array.isArray(video.content_tags) ? video.content_tags : [])//(video.tags);
      SetSurveyUrl(video.survey_url);
      SetDescription(video.description);
      SetTitle(video.title);
      Setthumbnai(video.thumbnail);
      SetselectedBrands(Array.isArray(video.products_indications_id) ? video.products_indications_id.map(x => x.product_id) : []);
      setCheckBrand(Array.isArray(video.products_indications_id) ? video.products_indications_id.map(x => x.product_id) : []);
      SetselectedIndications(Array.isArray(video.products_indications_id) ? video.products_indications_id.map(x => x.indication_id) : [])
      setCheckIndication(Array.isArray(video.products_indications_id) ? video.products_indications_id.map(x => x.indication_id) : []);
      SetselectedTherapeuticAreas(video.therapeutic_area_id);
      SetSelectedCategory(video.category_1_id);
      SetSelectedSubCategory(video.category_2_id);
      SetProductCharacteristics(Array.isArray(video.product_characteristic) ? video.product_characteristic : [])

      SetMaterials(Array.isArray(video.materials_uploaded) ? video.materials_uploaded : video.materials_uploaded ? JSON.parse(video.materials_uploaded) : []);
      setShareable(video.sharable_content);
      setTaxBrandInd(video.products_indications_id);

      let status = "Draft";
      SetpreviousStateCountryPublished(video.content_approved)
      let countriesStats = (video.content_approved);
      if (countriesStats) {
        if (Object.keys(countriesStats).length > 0) {
          Object.keys(countriesStats).forEach((countryId) => {
            if (["Active", "Pending", "Published"].includes(countriesStats[countryId].status)) {
              setEnabledFields(false);
            }
            if (countriesStats[countryId].status === "Active") {
              status = "Submitted";
              setEmbedVideoVisible(true);
            }
          });
          if (window.location.pathname.split('/')[2] !== undefined) {
            Object.keys(countriesStats).forEach((countryId) => {
              if (
                countriesStats[countryId].status == 'Active' ||
                countriesStats[countryId].status == 'Pending' ||
                countriesStats[countryId].status == 'Published') {
                // setNextButtonEnabled(false)
              }
            })
          } else {
            // setNextButtonEnabled(true)
          }
        }
      }
      setStatus(status);

      setOwner(video.created_by);
      SetLibraries(video.content_libraries)
      let recordingDate = (new Date(video.created_at)).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
      setRecordingDate(recordingDate);
      SetQuizDetails(video.quiz_credit);
      SetTempThumbnail(video.user_thumbnail);
      setAffiliateToPublish(video.content_approved);
      SetAffiliateObjModel(video.vvpm_details)
      SetAudience(video.audience_mapping ? video.audience_mapping : []);
      SetSpecialties(video.specialities ? video.specialities : []);
    }
    SetCheckInitialUpdate(true);
  }

  const setAffObjModel = (vvpm) => {

    for (var key in vvpm) {
      vvpm[key] = { to: "", from: "", rows: [] }
    }
    SetAffiliateObjModel(vvpm);

  }
  const GetOwnerSecondOwnerEmailModel = (affiliates_to_publish) => {
    let status = '';
    let allCountryList = Object.keys(affiliates_to_publish);
    if (allCountryList.length === 1) {
      status = affiliates_to_publish[allCountryList[0]].status == 'Active' ?
        'Published' : affiliates_to_publish[allCountryList[0]].status
    } else {
      const action_priority = [];
      allCountryList.map(country_code => {
        if (affiliates_to_publish[country_code].prevstatus) {
          action_priority.push(affiliates_to_publish[country_code].status);
        }
      })
      if (action_priority.length > 0) {
        // withing the action performed check the priority
        if (action_priority.includes('Published')) {
          status = 'Published';
        } else if (action_priority.includes('Active')) {
          status = 'Published';
        } else if (action_priority.includes('Pending')) {
          status = 'Pending';
        } else if (action_priority.includes('Inactive')) {
          status = 'Inactive';
        } else if (action_priority.includes('Rejected')) {
          status = 'Rejected';
        } else if (action_priority.includes('Draft')) {
          status = 'Draft';
        }
      }
    }
    let model = {
      ownerSecondOwnerMail: ownerSecondOwnerEmail,
      title: title,
      country: countryName,
      created: recordingDate,
      link: window.location.href,
      status: status,
    }
    return model;
  }

  let model = {
    duration: parseInt(props.duration),
    id: id,
    categories: functionalArea,
    affiliates: affiliates,
    tags: tags,
    surveyUrl: surveyUrl,
    description: removeTags(description.trim()),
    title: title,
    secondOwner: secondOwner,
    contentType: contentType,
    content_type: contentType,
    category_1_id: selectedCategory,
    category_2_id: selectedSubCategory,
    kaltura_id: kaltura_id,
    vvpmDetails: affiliateObjModel,
    vvpm_details: affiliateObjModel,
    content_libraries: content_libraries,
    status: "",
    created_by: created_by,
    updated_by: updated_by,
    speakers: selectedSpeakers,
    prod_characteristics: productCharacteristics,
    therapeuticAreas: selectedTherapeuticAreas,
    // taxTherapeuticArea: taxBrandInd,
    taxTherapeuticArea: taxBrandInd,
    brands: selectedBrands,
    indications: selectedIndications,
    thumbnail: thumbnailUrl,
    materials: materials,
    subtitles: subtitles,
    shareable: shareable,
    countries_approved: affiliateToPublish,
    content_approved: affiliateToPublish,
    libraries: libraries,
    audience: audience,
    specialties: specialties,
    user_thumbnail: tempThumbnail,
    quiz: quizDetails,
    multilingual_content: {
      Belgium: {
        Dutch: {
          checked: belgDutchChecked,
          title: belgDutchTitle ?? '',
          description: belgDutchDesc ?? '',
        },
        French: {
          checked: belgFrenchChecked,
          title: belgFrenchTitle ?? '',
          description: belgFrenchDesc ?? '',
        }
      },
      Switzerland: {
        German: {
          checked: switzGermanChecked,
          title: switzGermanTitle ?? '',
          description: switzGermanDesc ?? '',
        },
        French: {
          checked: switzFrenchChecked,
          title: switzFrenchTitle ?? '',
          description: switzFrenchDesc ?? '',
        }
      }
    }
  };
  const handleSubtitleCheck = () => {
    let subtitleArr = model.subtitles;
    let err = '';
    subtitleArr.forEach(subtitlerow => {
      if (subtitlerow.language === '') {
        err = 'Subtitle File is missing language;'
      }
      if (subtitlerow.file === '') {
        err = 'Subtitle File is missing file;'
      }
    })
    return err
  }
  const handleSave = async (redirect = false) => {
    setDisableClicks(true);
    let err = '';
    let newerr = '';
    if (title.trim() === "") {
      err += 'Title; '
    }
    if (title.trim() !== "" && (title.trim()).length > 60) {
      err += 'Title length; '
    }
    if ((cleanDescription(description.trim())) === "") {
      err += 'Description; ';
    }
    if ((cleanDescription(description.trim())) !== "" && cleanDescription(description.trim()).length > 5000) {
      err += 'Description length; ';
    }
    if (secondOwner == '' || secondOwner === null || secondOwner === undefined) {
      err += 'Second Owner; ';
    }
    if (functionalArea == '') {
      err += 'Functional Area; ';
    }
    if (!contentType || contentType === "") {
      err += 'Content Type; ';
    }
    if (selectedCategory.length == 0) {
      err += 'Category 1; ';
    }
    if (!model.taxTherapeuticArea?.length) {
      err += 'Indication; ';
    }
    if (affiliatesName.length === 0) {
      err += 'Country; ';
    }
    if (!props.showPreviewOfVideo && !isSaveButtonClicked) {
      // err += 'Video; ';
      newerr += 'Video not ready in kaltura. It may take some time. Please wait for video preview...';
    }
    if (!props.showPreviewOfVideo) {
      // err += 'Video; ';
      err += 'Video not ready in kaltura. It may take some time. Please wait for video preview...';
    }
    if (checkbrand.length > 0) {
      let acc = false;
      let indicationArray = checkindicaton.map(x => {
        let y = x;
        return y.product_id
      })

      for (let i = 0; i < checkbrand.length; i++) {
        let currBrand = checkbrand[i];
        let keycheck = currBrand?.key !== undefined ? currBrand.key : currBrand.product_id;
        if (!(indicationArray.includes(keycheck))) {
          acc = true;
        }
      }
      if (acc) {
        err += 'Indication Missing for some brands; ';
      }
    }
    // Subtitle check
    if (Array.isArray(model.subtitles) && model.subtitles.length > 0) {
      err += handleSubtitleCheck();
    }
    // if (expirationDateValidation) {
    //   err += 'VVPM Code; ';
    // }
    // if (expiry) {
    //   err += 'VVPM Expiry; ';
    // }
    // if (rowsCheckVVPM) {
    //   err += 'VVPM Document; ';
    //   // setExpirationDateValidation(true);
    //   // SetvalidationPopup(true);
    // }
    if (err === '') {
      SetIsSaveButtonClicked(false);
    }
    else if (newerr !== '') {
      setSnackbarMsg(`${newerr}`);
      setSnackbarType('error');
      setSnackbarOpen(true);
      setDisableClicks(false);
      return
    }
    else {
      setSnackbarMsg(`There are some mandatory fields missing: ${err}`);
      setSnackbarType('error');
      setSnackbarOpen(true);
      setDisableClicks(false);
      return
    }

    let publishCountriesObjNew = {};
    let publishedCountry = { ...affiliateToPublish };
    for (let i = 0; i < Object.keys(publishedCountry).length; i++) {
      publishCountriesObjNew[Object.keys(publishedCountry)[i]] = {
        name: publishedCountry[Object.keys(publishedCountry)[i]].name,
        prevstatus: publishedCountry[Object.keys(publishedCountry)[i]].status,
        status: 'Draft'
      }
    }
    setAffiliateToPublish(publishCountriesObjNew);
    model.countries_approved = publishCountriesObjNew;
    model.content_approved = publishCountriesObjNew;
    model.status = "draft";

    let copymodaldata = JSON.parse(JSON.stringify(model));
    // LILLYPLAY-3579 start
    let model_data = copymodaldata.content_approved;
    // Iterate through each country object
    for (let countryCode in model_data) {
      if (model_data.hasOwnProperty(countryCode)) {
        // Remove the 'prevstatus' property from each country object
        delete model_data[countryCode].prevstatus;
      }
    }
    copymodaldata.content_approved = model_data
    copymodaldata.countries_approved = model_data
    // LILLYPLAY-3579 end

    // Germany Issue Fix
    let model_speciality_data = copymodaldata.specialties;
    if(Array.isArray(model_speciality_data) && model_speciality_data.length > 0){
      model_speciality_data = model_speciality_data.filter(x => x.value.length > 0  )
    }
    copymodaldata.specialties = model_speciality_data
    // Germany Issue Fix end
    
    let contentdata = await contentService.UpdateContent(copymodaldata);
    if (contentdata?.content_id) {
      SetId(contentdata.content_id)
    }
    props.setContentData({
      ...contentdata,
      datakey: id == '' ? contentdata.country_codes : contentdata.affiliates,
      country_approve: Object.keys(contentdata.content_approved).length > 0 ? contentdata.content_approved : contentdata.countries_approved, content_data_id: id == '' ? contentdata.content_id : contentdata.id,
      affiliate_list: affiliates,
      affiliates_to_publish: affiliateToPublish,
      country_name: countryName,
      owner: created_by,
      secondary_owner: secondOwner,
      recording_date: recordingDate === '' ? new Date().toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : recordingDate,
    })
    setSnackbarMsg(`Your Draft is saved!`);
    setSnackbarType('success');
    setSnackbarOpen(true);
    setDisableClicks(false);
    if (redirect)
      setTimeout(() => props.goToStep(2, { action: 'save' }), 2000);
  }
  const SubmitCall = (isActive, publisherEmailSet) => {
    // Mail subject: status is not mentioned when pending content was rejected.
    let copymodaldata = JSON.parse(JSON.stringify(model));
    // LILLYPLAY-3579 start
    let model_data = copymodaldata.content_approved;
    // Iterate through each country object
    for (let countryCode in model_data) {
      if (model_data.hasOwnProperty(countryCode)) {
        // Remove the 'prevstatus' property from each country object
        delete model_data[countryCode].prevstatus;
      }
    }
    copymodaldata.content_approved = model_data
    // LILLYPLAY-3579 end
    // Mail subject: status is not mentioned when pending content was rejected. end

    // Germany Issue Fixes
    let model_speciality_data = copymodaldata.specialties;
    if(Array.isArray(model_speciality_data) && model_speciality_data.length > 0){
      model_speciality_data = model_speciality_data.filter(x => x.value.length > 0  )
    }
    copymodaldata.specialties = model_speciality_data
    //Germany Issue Fixed End

    contentService.UpdateContent(copymodaldata).then((data) => {
      contentService.CheckIfContentStatusHasChanged(previousStateCountryPublished, model.countries_approved,
        affiliates, GetOwnerSecondOwnerEmailModel(affiliateToPublish));
      if (id === '' || isActive) {
        let contentUrl = window.location.href;
        if (id === '') {
          contentUrl = window.location.href + "/" + data.content_id;
        }
        let date = new Date();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        let datestring = months[date.getMonth()] + " " + ("0" + date.getDate()).slice(-2) + " " + date.getFullYear() + ", " + strTime;
        if (sessionState.sessionValue?.payload?.user) {
          let roleData = sessionState.sessionValue?.payload?.user.role_ids;
          let flag = false;
          Object.keys(affiliateToPublish).forEach(x => {
            if (affiliateToPublish[x].prevstatus == 'Draft' && affiliateToPublish[x].status == 'Pending') {
              flag = true
            }
          })
          if (roleData.includes(3) && flag) {
            contentService.SendEmail(title, countryName, datestring, contentUrl, publisherEmailSet)
          }
        };
      }
      if (data?.id) {
        setDisableClicks(false);
        SetId(data.id)
      }
      if (data?.content_id) {
        setDisableClicks(false);
        SetId(data.content_id)
      }
      props.setContentData({
        ...data,
        datakey: id == '' ? data.country_codes : data.affiliates,
        country_approve: Object.keys(data.content_approved).length > 0 ? data.content_approved : data.countries_approved,
        content_data_id: id == '' ? data.content_id : data.id,
        affiliate_list: affiliates,
        affiliates_to_publish: affiliateToPublish,
        country_name: countryName,
        owner: created_by,
        secondary_owner: secondOwner,
        recording_date: recordingDate === '' ? new Date().toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : recordingDate,
      });
      props.goToStep(2);
    }).catch((err) => { });
  }
  const handleSubmit = async () => {
    setDisableClicks(true)
    //  Edit Case
    if (window.location.pathname.split('/')[2] !== undefined) {
      let affiliates = affiliateToPublish;
      if (Object.keys(affiliates).length > 0) {
        let keylist = Object.keys(affiliates)
        let validation = true;
        let datevaladition = true;
        let vvpmvalidationarray = keylist.map(countrycode => affiliates[countrycode].status);
        keylist.forEach(countrycode => {
          if (vvpmvalidationarray.includes('Active') || vvpmvalidationarray.includes('Pending')) {
            if (new Date(model.vvpm_details[countrycode]?.from) > new Date(model.vvpm_details[countrycode]?.to)) {
              datevaladition = false;
            }
            if (model.vvpm_details[countrycode]?.rows.length === 0)
              validation = false;
          } else {
            validation = true;
          }
        })
        if (!validation) {
          setSnackbarMsg(`There are some mandatory fields missing: VVPM Document`);
          setSnackbarType('error');
          setSnackbarOpen(true);
          setDisableClicks(false);
          return
        }
        if (!datevaladition) {
          setSnackbarMsg(`VVPM Document invalid: Date Expired`);
          setSnackbarType('error');
          setSnackbarOpen(true);
          setDisableClicks(false);
          return
        }
      } else {
        setSnackbarMsg(`There are some mandatory fields missing: VVPM Document`);
        setSnackbarType('error');
        setSnackbarOpen(true);
        setDisableClicks(false);
        return
      }
    }
    model.content_approved = model.countries_approved;
    model.status = "submitted";
    let publisherEmailSet = new Set();
    let isActive = false;
    let publishCountriesObjNew = {};
    if (id !== '') {
      let activeCountry = [];
      let publishedCountry = { ...affiliateToPublish };
      for (let i = 0; i < Object.keys(publishedCountry).length; i++) {
        // change here for publisher
        if (sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
          if (publishedCountry[Object.keys(publishedCountry)[i]].status) {
            publishCountriesObjNew[Object.keys(publishedCountry)[i]] = {
              name: publishedCountry[Object.keys(publishedCountry)[i]].name,
              status: publishedCountry[Object.keys(publishedCountry)[i]].status
            }
          }
          if (publishedCountry[Object.keys(publishedCountry)[i]].status === "Pending") {
            activeCountry.push(Object.keys(publishedCountry)[i]);
            isActive = true;
          }
        }
        // if internal all status is pending
        if (sessionState.sessionValue?.payload?.user.role_ids.includes(2) && !sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
          publishCountriesObjNew[Object.keys(publishedCountry)[i]] = {
            name: publishedCountry[Object.keys(publishedCountry)[i]].name,
            status: publishedCountry[Object.keys(publishedCountry)[i]].status,
          }
        }
      }
      model.countries_approved = { ...publishCountriesObjNew }
      let userStr = "country_code=" + activeCountry;
      contentService.GetPublishersByCountriesCodes(userStr).then((data) => {

        let jsondata = data.data;
        for (let i = 0; i < jsondata.length; i++) {
          publisherEmailSet.add(jsondata[i].user_email)
        }

        SubmitCall(isActive, Array.from(publisherEmailSet));

      }).catch((err) => { });
    }

  }

  // Handle Affiliates States
  const addAffiliates = (affiliate) => {
    SetAffiliatesName([...affiliatesName, affiliate])
  }
  const removeAffiliates = (affiliate_obj) => {
    var array = [...affiliatesName];
    const filteredArray = array.filter(item => item !== affiliate_obj);
    SetAffiliatesName(filteredArray);
    var filteredPublishArray = {};
    let selectedKeys = Object.keys(affiliateToPublish)
    for (let i = 0; i < selectedKeys.length; i++) {
      let key = selectedKeys[i];
      if (affiliate_obj.country_code !== key) {
        filteredPublishArray[key] = {
          name: affiliateToPublish[key].name,
          status: affiliateToPublish[key].status,
        }
      }
    }
    setAffiliateToPublish(filteredPublishArray);
  }


  const handleValidate = () => {
    let vvpmDetailsData = model?.vvpmDetails;
    let rowsCheckVVPM = true;
    let expiry = false
    for (var key in vvpmDetailsData) {
      if (!affiliates.includes(key)) {
        continue;
      }
      let singleRecord = vvpmDetailsData[key];
      if (!singleRecord?.to) {
        expiry = true;
        continue;
      }
      if (Array.isArray(singleRecord?.rows)) {
        if (singleRecord?.rows.length == 0) {
          rowsCheckVVPM = false;
          continue;
        }
      }
    }
    if (vvpmDetailsData.length == 0) {
      rowsCheckVVPM = true;
    }
    let err = '';
    let newerr = '';
    if (title.trim() === "") {
      err += 'Title; '
    }
    if (title.trim() !== "" && (title.trim()).length > 60) {
      err += 'Title length; '
    }
    if (cleanDescription(description.trim()) === "") {
      err += 'Description; ';
    }
    if (cleanDescription(description.trim()) !== "" && cleanDescription(description.trim()).length > 5000) {
      err += 'Description length; ';
    }
    if (secondOwner == '' || secondOwner === null || secondOwner === undefined) {
      err += 'Second Owner; ';
    }
    if (functionalArea == '') {
      err += 'Functional Area; ';
    }
    if (!contentType || contentType === "") {
      err += 'Content Type; ';
    }
    if (selectedCategory.length == 0) {
      err += 'Category 1; ';
    }
    if (!model.taxTherapeuticArea?.length) {
      err += 'Indication; ';
    }
    if (affiliatesName.length === 0) {
      err += 'Country; ';
    }
    if (!props.showPreviewOfVideo && !isSaveButtonClicked) {
      // err += 'Video; ';
      newerr += 'Video not ready in kaltura. It may take some time. Please wait for video preview...';
    }
    if (!props.showPreviewOfVideo) {
      // err += 'Video; ';
      err += 'Video not ready in kaltura. It may take some time. Please wait for video preview...';
    }
    if (checkbrand.length > 0) {
      let acc = false;
      let indicationArray = checkindicaton.map(x => {
        let y = x;
        return y.product_id
      })
      for (let i = 0; i < checkbrand.length; i++) {
        let currBrand = checkbrand[i];
        let keycheck = currBrand?.key !== undefined ? currBrand.key : currBrand.product_id;
        if (!(indicationArray.includes(keycheck))) {
          acc = true;
        }
      }
      if (acc) {
        err += 'Indication Missing for some brands; ';
      }
    }
    // Subtitle check
    if (Array.isArray(model.subtitles) && model.subtitles.length > 0) {
      err += handleSubtitleCheck();
    }
    // // if (expirationDateValidation) {
    // //   err += 'VVPM Code; ';
    // // }
    // if (expiry) {
    //   err += 'VVPM Code; ';
    // }
    // if (!rowsCheckVVPM) {
    //   err += 'VVPM Document; ';
    // }
    let checkStatus = []
    if (Object.keys(model?.content_approved).length) {
      checkStatus = Object.values(model?.content_approved).map((e) => e.status)
    }
    if (checkStatus?.length && (checkStatus.includes('Pending') || checkStatus.includes('Active') || checkStatus.includes('Published'))) {

      if (expiry) {
        err += 'VVPM Code; ';
      }
      if (!rowsCheckVVPM) {
        err += 'VVPM Document; ';
      }
    }

    // return;
    if (err === '') {
      // alert("rk")
      // return;
      SetIsSaveButtonClicked(false);
      if (id === '') {
        handleSave(true);
      } else {
        handleSubmit()
      }
    } else if (newerr !== '') {
      setSnackbarMsg(`${newerr}`);
      setSnackbarType('error');
      setSnackbarOpen(true);
      setDisableClicks(false);
      return
    } else {
      setSnackbarMsg(`There are some mandatory fields missing: ${err}`);
      setSnackbarType('error');
      setSnackbarOpen(true);
      setDisableClicks(false);
      return
    }
  }

  React.useEffect(() => {
  }, [affiliatesName]);

  React.useEffect(() => {
    let _affiliateObjModel = Object.assign({}, affiliateObjModel);
    let affilaiteLength = affiliatesName.length;
    for (let i = 0; i < affilaiteLength; i++) {
      if (affiliateObjModel[affiliatesName[i].country_code]) {
        if (Array.isArray(affiliateObjModel[affiliatesName[i].country_code].rows)) {
          if (affiliateObjModel[affiliatesName[i].country_code].rows.length == 0) {
            _affiliateObjModel[affiliatesName[i].country_code] = { from: "", to: "", rows: [] };
          }
          else {
            _affiliateObjModel[affiliatesName[i].country_code] = affiliateObjModel[affiliatesName[i].country_code]
          }
        }
      } else {
        _affiliateObjModel[affiliatesName[i].country_code] = { from: "", to: "", rows: [] };
      }
    }
    if (checkInitialUpdate) {
      SetAffiliateObjModel(_affiliateObjModel);
    }
    let publishCountriesObj = {};
    let countryNameArr = [];
    if (affiliatesName) {
      for (let i = 0; i < affiliatesName.length; i++) {

        if (!publishCountriesObj[affiliatesName[i].country_code]) {
          publishCountriesObj[affiliatesName[i].country_code] = {
            name: affiliatesName[i].country_name,
            status: affiliateToPublish[affiliatesName[i].country_code]?.status || 'Draft'
          }
        }
        countryNameArr.push(affiliatesName[i].country_name)
      }
    }
    setCountryName(countryNameArr);
    let uri = window.location.href;
    let videoId = uri.split('/')[uri.split('/').length - 1];
    if (videoId === "content-update" || changeCountryState === true) {
      //clear category 1 category 2
      SetSelectedCategory([]);
      SetSelectedSubCategory([]);
      setAffiliateToPublish(publishCountriesObj);
    }
  }, [affiliatesName]);
  const handleValidationPopup = () => {
    SetvalidationPopup(false);
  }

  const handleBack = () => {
    navigate('/content-management');
  }

  const confirmNavigationHandler = async () => {
    const model = new KalturaService();
    if(id === '') {
      await model.deleteMediaEntry(props.mediaEntry);
    }
    confirmNavigation();
  }
  const handlecountryChangePopup = (oldSelectedAffiliates, vvpm_details, content_approved) => {
    SetAffiliatesName(oldSelectedAffiliates);
    setAffiliateToPublish(content_approved);
    SetAffiliateObjModel(vvpm_details);
    setcountryChangePopup(false)
    setdetectChange(false)
  }
  React.useEffect(() => {
    if (id !== '' && detectChange) {
      setcountryChangePopup(true)
    }
  }, [detectChange]);

  React.useEffect(() => {
    if (id == '') {
      let vvpmdetails = {};
      let contentapproved = {};
      // when affiliateName is lesser than vvpm documents
      if (Object.keys(model.vvpm_details).length > affiliatesName.length) {
        let currentCountryKeys = affiliatesName.map(x => x.country_code);
        // parse the currentCountryKeys 
        currentCountryKeys.forEach(country_code => {
          // against each country code try to find vvpm details
          if (model.vvpm_details[country_code]) {
            vvpmdetails[country_code] = model.vvpm_details[country_code]
          } else {
            vvpmdetails[country_code] = { to: '', from: '', rows: [] }
          }
          // against each country code try to find the content approved details
          if (model.content_approved[country_code]) {
            contentapproved[country_code] = model.content_approved[country_code]
          } else {
            contentapproved[country_code] = { name: currentCountryKeys[country_code].name, status: 'Draft' }
          }
        })
        setAffiliateToPublish(contentapproved);
        SetAffiliateObjModel(vvpmdetails);
        model.content_approved = contentapproved;
        model.countries_approved = contentapproved;
        model.vvpmDetails = vvpmdetails;
        model.vvpm_details = vvpmdetails;
      }
    }
  }, [alternate])

  const handlecountryChangeCondition = () => {
    SetselectedTherapeuticAreas([])
    SetSelectedCategory([])
    SetSelectedSubCategory([])
    SetselectedBrands([])
    setCheckBrand([]);
    SetselectedIndications([])
    setCheckIndication([])
    SetLibraries([])
    SetAudience([])
    SetSpecialties([])
    SetSpecialtyMap([])

    let vvpmdetails = {};
    let contentapproved = {};
    // when affiliateName is lesser than vvpm documents
    if (Object.keys(model.vvpm_details).length > affiliatesName.length) {
      let currentCountryKeys = affiliatesName.map(x => x.country_code);
      // parse the currentCountryKeys 
      currentCountryKeys.forEach(country_code => {
        // against each country code try to find vvpm details
        if (model.vvpm_details[country_code]) {
          vvpmdetails[country_code] = model.vvpm_details[country_code]
        } else {
          vvpmdetails[country_code] = { to: '', from: '', rows: [] }
        }
        // against each country code try to find the content approved details
        if (model.content_approved[country_code]) {
          contentapproved[country_code] = model.content_approved[country_code]
        } else {
          contentapproved[country_code] = { name: currentCountryKeys[country_code].name, status: 'Draft' }
        }
      })
    } else {
      // user is adding affiliate
      // find out which affiliate is added 
      let countries_added = affiliatesName.filter(x => { if (!('avatar_attachment_id' in x)) return x }).map(x => x.country_code);
      let allCountryCodes = affiliatesName.map(x => x.country_code);
      allCountryCodes.forEach(country_code => {
        // against each country code try to find vvpm details
        if (model.vvpm_details[country_code]) {
          vvpmdetails[country_code] = model.vvpm_details[country_code]
        } else {
          vvpmdetails[country_code] = { to: '', from: '', rows: [] }
        }
        // against each country code try to find the content approved details
        if (model.content_approved[country_code]) {
          if (country_code, countries_added.includes(country_code) && model.content_approved[country_code].status === 'Draft') {
            contentapproved[country_code] = { ...model.content_approved[country_code], prevstatus: 'Draft' }
          } else {
            contentapproved[country_code] = model.content_approved[country_code]
          }
        } else {
          let country_name = affiliatesName.find(x => x.country_code == country_code).country_name;
          contentapproved[country_code] = { name: country_name, status: 'Draft', prevstatus: '' }
        }
      })
    }
    setAffiliateToPublish(contentapproved);
    SetAffiliateObjModel(vvpmdetails);
    setchangeCountryState(true)
    setcountryChangePopup(false)
    setdetectChange(false)
  }
  React.useEffect(() => {
  }, [props.thumbnailUrl]);

  return (
    <>
      <Modal open={s3UploadCheck} onClose={() => { SetS3UploadCheck(false) }}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => { SetS3UploadCheck(false) }}></button>
          </Grid>
          <Grid className='box'>
            <Grid className='attenSec attenSecvalidationImage'>
              <span className='attenIcnSec'>
                <ReportProblemIcon className='attenIcn' />
                Error
              </span>
              <div className="m20">
                {imageSizeCheck ? (
                  <Alert severity="warning">The thumbnail you selected does not meet the standard of file size. The file is too large. Please, select another thumbnail which size is less than 2.0MB</Alert>
                ) : ""}
                {imageTypeCheck ? (
                  <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail format. The file format is not allowed by system. Please, select another thumbnail which formats are: .jpg, .jpeg, .png.</Alert>
                ) : ""}
                {imageDimensionCheck ? (
                  <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail dimensions. The file is not of correct dimension. Please, select another thumbnail which dimensions are 1280x720px</Alert>
                ) : ""}
                {s3UploadErrorCheck ? (
                  <Alert severity="warning" f>The thumbnail you selected could not be uploaded. Please, try again later. If you submit the video now, Lilly Play will use the thumbnail that comes from Kaltura.</Alert>
                ) : ""}
              </div>
            </Grid>
          </Grid>
          <Grid className="modal1FoBtn modal-footer">
            <button type="button" className="modalBtn" onClick={() => { SetS3UploadCheck(false) }}>Ok</button>
          </Grid>
        </Grid>
      </Modal>

      <Modal open={countryChangePopup} onClose={() => handlecountryChangePopup(oldSelectedAffiliates, model.vvpm_details, model.content_approved)}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handlecountryChangePopup(oldSelectedAffiliates, model.vvpm_details, model.content_approved)}></button>
          </Grid>
          <Grid className='box'>
            <Grid className='attenSec attenSecvalidationImage'>
              <span className='attenIcnSec'>
                <ReportProblemIcon className='attenIcn' />
                Attention
              </span>
              <p className='attenTxt'>
                All the previously saved configurations that are country specific will be lost, Are you sure you want to continue?
              </p>
            </Grid>
          </Grid>
          <Grid className="modal1FoBtn modal-footer">
            <button type="button" className="modalBtn" onClick={() => handlecountryChangePopup(oldSelectedAffiliates, model.vvpm_details, model.content_approved)}>Cancel</button>
            <button type="button" className="modalBtn" onClick={handlecountryChangeCondition}>Ok</button>
          </Grid>
        </Grid>
      </Modal>

      <Modal open={validationPopup} onClose={handleValidationPopup}>
        <Grid className='paper modalstyle validationPopup'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={handleValidationPopup}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              {title === "" ? (
                <Alert severity="warning">Enter a Title</Alert>
              ) : ""}
              {description === "" ? (
                <Alert severity="warning">Enter a Content Description</Alert>
              ) : ""}
              {contentType === "" ? (
                <Alert severity="warning">Select Content Type</Alert>
              ) : ""}
              {selectedBrands.length === 0 ? (
                <Alert severity="warning">Please select at least one Category 1</Alert>
              ) : ""}
              {affiliates.length === 0 ? (
                <Alert severity="warning">Please select at least one Affiliate</Alert>
              ) : ""}
              {!props.showPreviewOfVideo && !isSaveButtonClicked ? (
                <Alert severity="warning">Video not ready in kaltura. It may take some time. Please wait for video preview...</Alert>
              ) : ""}
              {expirationDateValidation ? (
                <Alert severity="warning">Please, select the VVPM code related to your content.</Alert>
              ) : ""}
              {taxBrands.length === 0 ? (
                <Alert severity="warning">Please, select the taxonomy brand related to the content.</Alert>
              ) : ""}
              {taxIndication.length === 0 ? (
                <Alert severity="warning">Please, select the taxonomy indication related to the content.</Alert>
              ) : ""}
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer ">
            <button type="button" onClick={handleValidationPopup} className="modalBtn validationpopupFooter">Close</button>
          </Grid>
        </Grid>
      </Modal>

      <>
        <Modal open={showPrompt} >
          <Grid className='paper modalstyle validationPopup'>
            <Grid className="modal-title">
              <button className='closeModal' onClick={cancelNavigation}></button>
            </Grid>
            <Grid className="modal-body">
              <div className="m20">

                <Alert severity="warning">Your Changes will be lost!</Alert>

              </div>
            </Grid>
            <Grid className="modal1FoBtn modal-footer ">
              <button type="button" onClick={cancelNavigation} className="modalBtn ">Cancel</button>
              <button type="button" onClick={confirmNavigationHandler} className="modalBtn ">OK</button>
            </Grid>
          </Grid>
        </Modal>
      </>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => { setSnackbarOpen(false) }}>
        <Alert
          className={`snackbar ${snackbarType}`}
          onClose={() => { setSnackbarOpen(false) }}
          severity={`${snackbarType}`}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
      <div className='content-update-page'>
        <div className="grey_row">
          <div className="inter-page-navgation">
            <button onClick={() => handleBack()}> <ArrowBackIcon /> Back </button>
            <div className='align-right'>
              {enableFields ? <button className={disableClicks ? 'disabled' : ''} onClick={disableClicks ? () => { } : async () => { await handleSave(); }} > <SaveOutlinedIcon /> Save as Draft </button> : null}
              <button className={disableClicks ? 'red disabled' : 'red'}
                //  onClick={async () => { await handleSubmit(); }}

                onClick={() => { disableClicks ? console.log('noAction') : handleValidate() }}
              >
                Next <ArrowForwardIcon /> </button>
            </div>
          </div>
        </div>

        <PreviewAndType id={id} enableFields={enableFields} created_by={created_by} status={
          id ? ((Object.keys(affiliateToPublish)).length > 0 ? Object.values(affiliateToPublish).map(x => x.status).includes('Active') ||
            Object.values(affiliateToPublish).map(x => x.status).includes('Published')
            ? 'Published' : (
              Object.values(affiliateToPublish).map(x => x.status).includes('Pending') ?
                'Pending' : (
                  Object.values(affiliateToPublish).map(x => x.status).includes('Inactive') ?
                    'Inactive' : (
                      Object.values(affiliateToPublish).map(x => x.status).includes('Rejected') ?
                        'Rejected' :
                        'Draft'
                    )
                )
            ) : status) : ''
        } kaltura_id={kaltura_id} recordingDate={recordingDate}
          setSecondOwnerArray={setSecondOwnerArray} setSecondOwnerVal={setSecondOwnerVal}
          affiliates={affiliates} Setcreated_by={Setcreated_by} SetS3UploadCheck={SetS3UploadCheck} SetS3UploadErrorCheck={SetS3UploadErrorCheck} SetImageSizeCheck={SetImageSizeCheck} SetImageTypeCheck={SetImageTypeCheck}
          SetContentType={SetContentType} imageDimensionCheck={imageDimensionCheck} contentType={contentType} SetImageDimensionCheck={SetImageDimensionCheck}
          showPreviewOfVideo={props.showPreviewOfVideo} SetShowPreviewOfVideo={props.SetShowPreviewOfVideo} SetDuration={props.SetDuration}
          secondOwner={secondOwner} SetSecondOwner={SetSecondOwner}
          owner={owner} setOwner={setOwner} SetOwnerSecondOwnerEmail={SetOwnerSecondOwnerEmail}
          SetTempThumbnail={SetTempThumbnail} tempThumbnail={tempThumbnail}
          uploadToken={props.uploadToken} mediaEntry={props.mediaEntry}
          embedvideovisible={embedvideovisible}
          SetKalturaId={props.SetKalturaId} SetThumbnailUrl={props.SetThumbnailUrl}
          SetUploadPercentage={props.SetUploadPercentage} uploadPercentage={props.uploadPercentage} setcontentlost={setcontentlost} isRolePublisher={isRolePublisher} IskalturaUploaded={props.IskalturaUploaded} thumbnailUrl={thumbnailUrl} />
        <ContentInputFields
          id={id}
          setcontentlost={setcontentlost}
          SetDescription={SetDescription}
          description={description}
          SetContentType={SetContentType}
          contentType={contentType}
          SetFunctionalArea={SetFunctionalArea}
          functionalArea={functionalArea}
          SetTags={SetTags}
          tags={tags}
          setSelectedSpeakers={setSelectedSpeakers}
          selectedSpeakers={selectedSpeakers}
          speakerData={speakerData}
          secondOwnerArry={secondOwnerArry}
          secondOwnerVal={secondOwnerVal}
          secondOwner={secondOwner}
          SetSecondOwner={SetSecondOwner}
          SetTitle={SetTitle}
          title={title}
          SetAffiliates={SetAffiliates}
          affiliates={affiliates}
          SetAffiliatesName={SetAffiliatesName}
          setdetectChange={setdetectChange}
          enableFields={enableFields}
          oldSelectedAffiliates={setOldSelectedAffiliates}
          alternate={alternate}
          setAlternate={setAlternate}
          finalSelectedList={affiliatesName}
        />
        <ContentCategory id={id} enableFields={enableFields} SetCategories={SetCategories} categories={categories} setcontentlost={setcontentlost} affiliates={affiliatesName} affiliatesReload={affiliates} allcategory={props.allcategory} selectedTherapeuticAreas={selectedTherapeuticAreas} SetselectedTherapeuticAreas={SetselectedTherapeuticAreas} selectedBrands={selectedBrands} SetselectedBrands={SetselectedBrands} selectedIndications={selectedIndications} setCheckBrand={setCheckBrand} setCheckIndication={setCheckIndication} SetselectedIndications={SetselectedIndications} libraries={libraries} SetLibraries={SetLibraries} taxBrandInd={taxBrandInd} setTaxBrandInd={setTaxBrandInd} setbrandTaxnomy={setbrandTaxnomy}
          selectedCategory={selectedCategory}
          SetSelectedCategory={SetSelectedCategory}
          selectedSubCategory={selectedSubCategory}
          SetSelectedSubCategory={SetSelectedSubCategory} />
        <VVPMDetail subType={subType} classificationCode={classificationCode} setcontentlost={setcontentlost} affiliates={affiliates} affiliatesName={affiliatesName} VVPMDetails={VVPMDetails} SetVVPMDetails={SetVVPMDetails} VVPMFromDate={VVPMFromDate} setVVPMFromDate={setVVPMFromDate} VVPMExpirationDate={VVPMExpirationDate} setVVPMExpirationDate={setVVPMExpirationDate} SetAffiliateObjModel={SetAffiliateObjModel} affiliateObjModel={affiliateObjModel} vvpmAffiliateMap={vvpmAffiliateMap} SetVVPMAffiliateMap={SetVVPMAffiliateMap} enableFields={enableFields}
        />
        <Audience setcontentlost={setcontentlost} affiliates={affiliatesName} audience={audience} SetAudience={SetAudience} enableFields={enableFields} />
        <Specialities setcontentlost={setcontentlost} affiliates={affiliatesName} specialties={specialties} SetSpecialties={SetSpecialties} changeCountryState={changeCountryState} enableFields={enableFields}
        />
        <div className='additional-content-section'>
          <Grid container>
            <ContentSubtitle enableFields={enableFields} setcontentlost={setcontentlost} created_by={created_by} SetImageDimensionCheck={SetImageDimensionCheck} SetS3UploadCheck={SetS3UploadCheck} SetS3UploadErrorCheck={SetS3UploadErrorCheck} SetImageTypeCheck={SetImageTypeCheck} SetImageSizeCheck={SetImageSizeCheck} id={id} materials={materials} SetMaterials={SetMaterials} subtitles={subtitles} SetSubtitles={SetSubtitles} kaltura_id={kaltura_id} />
            <AddProducts enableFields={enableFields} setcontentlost={setcontentlost} productCharacteristics={productCharacteristics} SetProductCharacteristics={SetProductCharacteristics} />
          </Grid>
        </div>
        {window.location.pathname.split('/')[2] !== undefined ?
          <ContentQuiz setcontentlost={setcontentlost} SetQuizId={SetQuizId} quizId={quizId} SetQuizSelected={SetQuizSelected} quizSelected={quizSelected} SetQuizDetails={SetQuizDetails} quizDetails={quizDetails} enableFields={enableFields} />
          : null}
        <ContentShare enableFields={enableFields} setcontentlost={setcontentlost} shareable={shareable} setShareable={setShareable} />
        {window.location.pathname.split('/')[2] !== undefined ? sessionState.sessionValue?.payload?.user.role_ids.includes(3) ?
          <ContentPublish title={title} type={contentType} publisherCountryList={publisherCountryList} setcontentlost={setcontentlost} affiliatesName={affiliatesName} affiliateToPublish={affiliateToPublish} setAffiliateToPublish={setAffiliateToPublish} />
          : null : null}


        <div className="grey_row">
          <div className="inter-page-navgation">
            <button onClick={() => handleBack()}> <ArrowBackIcon /> Back </button>
            <div className='align-right'>
              {enableFields ? <button className={disableClicks ? 'disabled' : ''} onClick={disableClicks ? () => { } : async () => { await handleSave(); }} > <SaveOutlinedIcon /> Save as Draft </button> : null}
              <button className={disableClicks ? 'red disabled' : 'red'}
                //  onClick={async () => { await handleSubmit(); }}

                onClick={() => { disableClicks ? console.log('noAction') : handleValidate() }}
              >
                Next <ArrowForwardIcon /> </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ContentUpdate