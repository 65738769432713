/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import AppBar from '@material-ui/core/AppBar';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import promotional from '../../../public/img/icn_promotional.svg';
import events from '../../../public/img/icn_events.svg';
import educational from '../../../public/img/icn_educational.svg';
import '../country-profile/country-profile.css';

import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { CountryService } from '../Services/country-service';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const Library = (props) => {
    const [libOff, setlibOff] = React.useState('1');
    const [openLibraryModal, setopenLibraryModal] = React.useState(false);
    const [value, setValue] = React.useState('1');
    const [libStatus, setlibStatus] = React.useState(false);
    const [libHTA, setlibHTA] = React.useState(false);
    const [libraryText, setlibraryText] = React.useState([]);
    const [upldBool, setUpldBool] = React.useState(false);
    const [openSwitch, setOpenSwitch] = React.useState(false);

    const [libraryArray, setLibraryArray] = React.useState([]);
    const [hideshowAdd, sethideshowAdd] = React.useState(true);
    const [hideshowImageelector, sethideshowImageelector] = React.useState([]);

    const [fixedMenuDropDown, setfixedMenuDropDown] = React.useState([]);

    const [librarythraupatiArea, setlibrarythraupatiArea] = React.useState([]);
    const [librarybrand, setlibrarybrand] = React.useState([]);
    const [libraryindication, setlibraryindication] = React.useState([]);

    const [librarythraupatiAreacheck, setlibrarythraupatiAreacheck] = React.useState([]);
    const [librarybrandcheck, setlibrarybrandcheck] = React.useState([]);
    const [libraryindicationcheck, setlibraryindicationcheck] = React.useState([]);

    const [hideshowlevel, sethideshowlevel] = React.useState({});

    const [alllibrarythraupatiArea, setAlllibrarythraupatiArea] = React.useState([]);
    const [alllibrarybrand, setAlllibrarybrand] = React.useState([]);
    const [alllibraryindication, setAlllibraryindication] = React.useState([]);

    const [activeIndex, setactiveIndex] = React.useState('');
    const [activeTid, setactiveTid] = React.useState('');
    const [activeid, setactiveid] = React.useState('');
    const [activebrandid, setactivebrandid] = React.useState('');


    const [activeBrand, setactiveBrand] = React.useState([]);
    const [activeIndication, setactiveIndication] = React.useState([]);

    const [activeIndicationLevel3, setactiveIndicationLevel3] = React.useState([]);
    const [selectedIndicationLevel3, setselectedIndicationLevel3] = React.useState([]);


    const [selectedBrand, setselectedBrand] = React.useState([]);
    const [selectedIndication, setselectedIndication] = React.useState([]);

    const [selectedfixedMenu, setselectedfixedMenu] = React.useState([]);

    const [activeLevel1, setactiveLevel1] = React.useState('');
    const [activeLevel2, setactiveLevel2] = React.useState('');

    const [activeidforClass, setactiveidforClass] = React.useState('');
    const [activeidforClasslevel2, setactiveidforClasslevel2] = React.useState('');
    const [openLibraryModalActive, setopenLibraryModalActive] = React.useState(false);

    const [imagearray, setimagearray] = React.useState([]);



    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handlelibOff = () => {
        setopenLibraryModal(true);
        setlibraryText([{ value: 'Libraries are turned off.' }]);
    }
    const handlelibHTA = () => {
        props.Settatoogle(!props.tatoogle)
        if (!props.tatoogle) {
            setlibraryText([{ value: 'Warning! TAs,Brands and Indications will not be displayed in all pages.' }]);
            setopenLibraryModal(true);
        }

        // setlibraryText([{ value: 'Warning! TAs,Brands and Indications will not be displayed in all pages.' }]);
    }
    const handleLibraryClose = () => {
        setopenLibraryModal(false)
    }
    React.useEffect(() => {
        //for development purpose
        let service = new CountryService();
        let uri = window.location.href;

        let affiliate_id = uri.split('/')[uri.split('/').length - 1];
        //alert(affiliate_id)
        service.getLibrary({ affiliate_id: affiliate_id }).then((data) => {
            let list = [];
            let librarydata = data.data;
            let imageselect = []
            if (librarydata.length > 0) {
                for (let i = 0; i < librarydata.length; i++) {
                    let tid = [];
                    let bid = [];
                    let iid = [];
                    for (let j = 0; j < librarydata[i].library_categories.length; j++) {
                        let cati = librarydata[i].library_categories[j].toString()
                        const catIDs = cati.split("_");
                        if (catIDs[0]) {
                            if (!tid.includes(catIDs[0])) {
                                tid.push(catIDs[0])
                            }
                        }
                        if (catIDs[1]) {
                            if (!bid.includes(catIDs[1])) {
                                bid.push(catIDs[1])
                            }
                        }
                        if (catIDs[2]) {
                            if (!iid.includes(catIDs[2])) {
                                iid.push(catIDs[2])
                            }
                        }
                    }
                    if (!librarydata[i].staticimage) {
                        // service.GetS3File("Country/library/" + librarydata[i].thumbnail_url).then((data) => {
                        list.push({ library_id: librarydata[i].library_id, library_name: librarydata[i].library_name, thumbnail_url: librarydata[i].thumbnail_url, status: librarydata[i].status, staticimage: librarydata[i].staticimage, therapeuticareas: tid, brand: bid, indication: iid, categories: librarydata[i].library_categories, image: librarydata[i].thumbnail_url, fixedmenu: librarydata[i].library_fixed_filter ? JSON.parse(librarydata[i].library_fixed_filter) : [] })
                        // });
                    } else {
                        list.push({ library_id: librarydata[i].library_id, library_name: librarydata[i].library_name, thumbnail_url: librarydata[i].thumbnail_url, status: librarydata[i].status, staticimage: librarydata[i].staticimage, therapeuticareas: tid, brand: bid, indication: iid, categories: librarydata[i].library_categories, image: '', fixedmenu: librarydata[i].library_fixed_filter ? JSON.parse(librarydata[i].library_fixed_filter) : [] })
                    }


                    imageselect[i] = false
                }
                sethideshowImageelector(imageselect)
                let libthercheck = [...librarythraupatiAreacheck]
                let libindicdcheck = [...libraryindicationcheck]
                let libbrandcheck = [...librarybrandcheck]
                let hubCheck = {}
                for (let k = 0; k < list.length; k++) {
                    //alert(k)
                    if (list[k].therapeuticareas.length > 0) {
                        if (list[k].brand.length > 0) {
                            libbrandcheck[k] = 2
                            libindicdcheck[k] = 3
                            hubCheck[k + "_level2"] = true;
                            hubCheck[k + "_level3"] = true;
                        } else {
                            if (list[k].indication.length > 0) {
                                libbrandcheck[k] = 3
                                hubCheck[k + "_level2"] = true;
                            }
                        }
                        libthercheck[k] = 1;

                        // hideshowlevel[i + "_level2"]
                    } else if (list[k].therapeuticareas.length == 0 && list[k].brand.length > 0) {
                        libthercheck[k] = 2;
                        hubCheck[k + "_level2"] = true;
                        libbrandcheck[k] = 3
                    } else if (list[k].therapeuticareas.length == 0 && list[k].brand.length == 0 && list[k].indication.length > 0) {
                        libthercheck[k] = 3;
                    }
                }
                
                setLibraryArray(list);
                if (libraryArray.length > 3){
                    sethideshowAdd(false)
                }
                setlibrarythraupatiAreacheck(libthercheck)
                setlibrarybrandcheck(libbrandcheck)
                setlibraryindicationcheck(libindicdcheck)
                sethideshowlevel(hubCheck)

                let fixedMenuDD = [];

                fixedMenuDD.push({ id: 1, name: "Thereputic Areas" })
                fixedMenuDD.push({ id: 2, name: "Category 1" })
                fixedMenuDD.push({ id: 3, name: "Category 2" })
                setfixedMenuDropDown(fixedMenuDD);
            } else {
                // let lists = {};
                // lists.library_name = ""
                // lists.staticimage = false
                // lists.thumbnail_url = ""
                // lists.status = false
                // lists.therapeuticareas = [];
                // lists.brand = [];
                // lists.indication = []
                // lists.categories = []
                // lists.image = ""
                // lists.fixedmenu = []
                // setLibraryArray([...libraryArray, lists]);
                let fixedMenuDD = [];

                fixedMenuDD.push({ id: 1, name: "Thereputic Areas" })
                fixedMenuDD.push({ id: 2, name: "Category 1" })
                fixedMenuDD.push({ id: 3, name: "Category 2" })
                setfixedMenuDropDown(fixedMenuDD);
            }

        })



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
   
    React.useEffect(() => {
        //     const [imagearray, setimagearray] = React.useState([]);
        let newimageArr = Object.assign({}, imagearray);
        let service = new CountryService();

        for (let i = 0; i < libraryArray.length; i++) {
            if (!libraryArray[i].staticimage && libraryArray[i].library_id) {
                // alert('d')
                service.GetS3File(libraryArray[i].thumbnail_url).then((data) => {
                    newimageArr['image' + libraryArray[i].library_id] = data
                    newimageArr = JSON.parse(JSON.stringify(newimageArr))
                    // let tempImageArray = Object.assign([],imagearray);
                    // tempImageArray.push();
                    if(i ==  libraryArray.length-1){
                        setimagearray(newimageArr);
                    }
                    // setimagearray(...imagearray, newimageArr);
                })
            }else{
                if(i ==  libraryArray.length-1){
                    setimagearray(newimageArr);
                }
            }
        }
        props.SetlibraryArray(libraryArray)
        if (libraryArray.length > 3){
            sethideshowAdd(false)
        }
        let libthercheck = [...librarythraupatiAreacheck]
        let libindicdcheck = [...libraryindicationcheck]
        let libbrandcheck = [...librarybrandcheck]
        let hubCheck = {}
        for (let k = 0; k < libraryArray.length; k++) {
            if (libraryArray[k].therapeuticareas.length > 0) {
                // libbrandcheck[k] = 2
                // libindicdcheck[k] = 3
                libthercheck[k] = 1;
                if (libraryArray[k].brand.length > 0) {
                    libbrandcheck[k] = 2
                    libindicdcheck[k] = 3
                    hubCheck[k + "_level2"] = true;
                    hubCheck[k + "_level3"] = true;
                }
                if (libraryArray[k].brand == 0 && libraryArray[k].indication.length > 0) {
                    libbrandcheck[k] = 3
                    hubCheck[k + "_level2"] = true;
                }
                // hubCheck[k + "_level2"] = true;
                // hubCheck[k + "_level3"] = true;

            } else if (libraryArray[k].therapeuticareas.length == 0 && libraryArray[k].brand.length > 0) {
                libthercheck[k] = 2;
                libbrandcheck[k] = 3
                hubCheck[k + "_level2"] = true;
            } else if (libraryArray[k].therapeuticareas.length == 0 && libraryArray[k].brand.length == 0 && libraryArray[k].indication.length > 0) {
                libthercheck[k] = 3;
            }
        }
        setlibrarythraupatiAreacheck(libthercheck)
        setlibrarybrandcheck(libbrandcheck)
        setlibraryindicationcheck(libindicdcheck)
        sethideshowlevel(hubCheck)
    }, [libraryArray, libraryArray.length])

    const handleAddLibrary = () => {
        //alert(libraryArray.length)
        if (libraryArray.length < 4) {
            if (libraryArray.length == 3) {
                sethideshowAdd(false)
            } else {
                sethideshowAdd(true)
            }
            let list = {};
            list.library_name = ""
            list.staticimage = false
            list.thumbnail_url = ""
            list.status = false
            list.therapeuticareas = [];
            list.brand = [];
            list.indication = []
            list.categories = []
            list.fixedmenu = []
            setLibraryArray([...libraryArray, list]);

        } else {
            sethideshowAdd(false)
        }
    }

    const handleDeleteLibrary = (index, arr) => {
        if (libraryArray.length == 1) {
            // let list = {};
            // list.library_name = ""
            // list.staticimage = false
            // list.thumbnail_url = ""
            // list.status = false
            // list.therapeuticareas = [];
            // list.brand = [];
            // list.indication = []
            // list.categories = []
            // list.fixedmenu = []
            setLibraryArray([]);
        } else {

            setLibraryArray(libraryArray => (
                libraryArray.filter((value, i) => i !== index)
            ));
            sethideshowAdd(true)
        }
        if (arr.library_id) {
            let newlist = [...props.deletedlibraryId]
            newlist.push(arr.library_id)
            props.setdeletedlibraryId(newlist)
        }
    }

    const imgPreviewHandler = (file, index) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileName = 'Country/library/'+ (new Date()).getTime().toString()+"-"+file.name;
                let newArr = [...libraryArray];
                newArr[index]['image'] = fileName;
                newArr[index]['staticimage'] = false;
                newArr[index]['thumbnail_url'] =fileName;// reader.result;//false;
                setLibraryArray(newArr)
                let imgSelec = [...hideshowImageelector];
                imgSelec[index] = false;
                sethideshowImageelector(imgSelec)
                let countryService = new CountryService();
                countryService.UploadFileToS3(fileName, reader.result).then((data) => {
                    countryService.GetS3File(fileName , 'library').then((_data) => {
                        let newArr2 = [...newArr];//[...libraryArray];
                        newArr2[index]['thumbnail_url'] =fileName;//reader.result;// fileName;//.binarySring;
                        setLibraryArray(newArr2)
                    });
                });
                // speakerService.GetS3File().then((data) => {
                //     setImgPreview(data);
                // });
                //setImgPreview(reader.result);
            }
        }
    }

    const showhideLevel = (index, level) => {
        sethideshowlevel({ ...hideshowlevel, [index + '_' + level]: true })
    }

    const refreshIndec = (index, level) => {
        // alert(index)
        let newArr = [...libraryArray];
        if (level == "level3") {
            sethideshowlevel({ ...hideshowlevel, [index + '_' + level]: undefined })
            newArr[index]['indication'] = [];
            let categories = newArr[index]['categories'];

            let cattid = [];

            for (let j = 0; j < categories.length; j++) {
                let cati = categories[j].toString()
                const catIDs = cati.split("_");
                let tid = "_";
                let bid = "_";
                if (catIDs[0]) {
                    tid = catIDs[0];
                }
                if (catIDs[1]) {
                    bid = catIDs[1];
                }
                cattid.push(tid + "_" + bid)
            }
            newArr[index]['categories'] = cattid;
            setselectedIndicationLevel3([])
            // setlibraryindicationcheck
            // setlibrarybrandcheck([])
            setlibraryindicationcheck([])
            // librarybrandcheck[index]
        } else if (level == "level2") {
            sethideshowlevel({ ...hideshowlevel, [index + '_' + level]: undefined })
            sethideshowlevel({ ...hideshowlevel, [index + '_level3']: undefined })
            newArr[index]['indication'] = [];
            newArr[index]['brand'] = [];
            let categories = newArr[index]['categories'];

            let cattid = [];

            for (let j = 0; j < categories.length; j++) {
                let cati = categories[j].toString()
                const catIDs = cati.split("_");
                let tid = "_";
                let bid = "_";
                if (catIDs[0]) {
                    tid = catIDs[0];
                }
                else {
                    bid = catIDs[1];
                }
                cattid.push(tid + "_" + bid)
            }
            newArr[index]['categories'] = cattid;
            setselectedBrand([])
            setselectedIndication([])
            setselectedIndicationLevel3([])
            setlibrarybrandcheck([])
            setlibraryindicationcheck([])
        } else if (level == "level1") {
            sethideshowlevel({ ...hideshowlevel, [index + '_' + level]: undefined })
            sethideshowlevel({ ...hideshowlevel, [index + '_level3']: undefined })
            sethideshowlevel({ ...hideshowlevel, [index + '_level2']: undefined })
            newArr[index]['indication'] = [];
            newArr[index]['brand'] = [];
            newArr[index]['therapeuticareas'] = [];
            newArr[index]['categories'] = [];
        }
        setLibraryArray(newArr)
    }

    React.useEffect(() => {
        let therapeuticAreas = [];
        let brands = [];
        let indication = [];
        let categories = [];
        setlibOff(props.libraryActive)
        // selectedTAIds= {selectedTAIds}
        //                 selectedCat1Ids = {selectedCat1Ids}
        //                 selectedCat2Ids= {selectedCat2Ids}
        for (let i = 0; i < props.brands.length; i++) {
            if (props.selectedCat1Ids.includes(props.brands[i]['category_1_id'])) {
                categories.push(props.brands[i]['therapeutic_area_id'] + "_" + props.brands[i]['category_1_id'])
            }
        }
        for (let i = 0; i < props.indications.length; i++) {
            if (props.selectedCat2Ids.includes(props.indications[i]['category_2_id'])) {
                for (let j = 0; j < props.brands.length; j++) {
                    if (props.brands[j]['category_1_id'] == props.indications[i]['category_1_id']) {
                        let index = categories.indexOf(props.brands[j]['therapeutic_area_id'] + "_" + props.indications[i]['category_1_id']);
                        if (index != -1) {
                            categories.splice(index, 1);
                        }
                        categories.push(props.brands[j]['therapeutic_area_id'] + "_" + props.indications[i]['category_1_id'] + "_" + props.indications[i]['category_2_id'])
                    }
                }
            }
        }
        for (let i = 0; categories.length > i; i++) {
            const catIDs = categories[i].split("_");
            if (catIDs.length > 0) {
                if (props.therapeuticAreas) {
                    for (let j = 0; j < props.therapeuticAreas.length; j++) {
                        if (props.therapeuticAreas[j]['therapeutic_area_id'] == catIDs[0]) {
                            therapeuticAreas.push(props.therapeuticAreas[j])
                        }
                    }
                    for (let j = 0; j < props.brands.length; j++) {
                        if (props.brands[j]['category_1_id'] == catIDs[1]) {
                            brands.push(props.brands[j])
                        }
                    }
                    for (let j = 0; j < props.indications.length; j++) {
                        if (props.indications[j]['category_2_id'] == catIDs[2]) {
                            indication.push(props.indications[j])
                        }
                    }
                }
            }
        }
        setAlllibrarythraupatiArea(filterArray(therapeuticAreas, "t"))
        // setAlllibrarybrand(filterArray(brands, "b"))
        setAlllibrarybrand(filterArray(brands, "b"))
        setAlllibraryindication(filterArray(indication, "i"))
        
        
    }, [props]);

    const filterArray = (arr, category) => {
        let resArr = [];
        arr.forEach(function (item) {
            if (category == 't') {
                let i = resArr.findIndex(x => x.therapeutic_area_name == item.therapeutic_area_name);
                if (i <= -1) {
                    resArr.push({ therapeutic_area_id: item.therapeutic_area_id, therapeutic_area_name: item.therapeutic_area_name });
                }
            } else if (category == 'b') {
                if (item.category_1_id) {
                    let i = resArr.findIndex(x => x.category_1_id == item.category_1_id);
                    if (i <= -1) {
                        resArr.push({ therapeutic_area_id: item.therapeutic_area_id, brand_id: item.category_1_id, brand_name: item.category_1_name ? item.category_1_name : item.brand_name, category_1_id: item.category_1_id });
                    }
                } else {
                    let i = resArr.findIndex(x => x.brand_id == item.brand_id);
                    if (i <= -1) {
                        resArr.push({ therapeutic_area_id: item.therapeutic_area_id, brand_id: item.brand_id, brand_name: item.brand_name });
                    }
                }

            } else if (category == 'i') {
                for (let j = 0; j < props.indications.length; j++) {

                    if (item.category_2_id) {
                        if (props.indications[j]['category_2_id'] == item.category_2_id) {
                            let i = resArr.findIndex(x => x.category_2_id == item.category_2_id);
                            if (i <= -1) {
                                resArr.push({ therapeutic_area_id: item.therapeutic_area_id, brand_id: props.indications[j]['category_1_id'], indication_name: item.category_2_name ? item.category_2_name : item.indication_name, indication_id: item.category_2_id, category_2_id: item.category_2_id });
                            }
                        }
                    } else {
                        if (props.indications[j]['category_2_id'] == item.indication_id) {
                            let i = resArr.findIndex(x => x.indication_id == item.indication_id);
                            if (i <= -1) {
                                resArr.push({ therapeutic_area_id: item.therapeutic_area_id, brand_id: item.brand_id, indication_name: item.indication_name, indication_id: item.indication_id });
                            }
                        }
                    }
                }

            }

        });
        return resArr;
    }
    React.useEffect(() => {
    }, [hideshowlevel]);
    React.useEffect(() => {
    }, [selectedIndicationLevel3]);

    const setactiveCat = (index, tid, id, type) => {
        setactiveLevel2('')
        setactiveLevel1('level1' + index)
        setactiveid(id)
        setactiveidforClass(id + 'level1' + index)
        setactiveidforClasslevel2()
        let list = [];
        let selectedlist = [];
        let categories = [];

        for (let i = 0; i < libraryArray[index]['categories'].length; i++) {
            let lindex = (libraryArray[index]['categories'][i]).toString();
            const catIDs = lindex.split("_");
            if (catIDs.length > 0) {
                if (type == 't') {
                    if (librarybrandcheck[index] == 2) {
                        for (let j = 0; j < alllibrarybrand.length; j++) {
                            if (alllibrarybrand[j].therapeutic_area_id == id) {
                                list.push(alllibrarybrand[j])
                            }
                            if (catIDs[1] == alllibrarybrand[j].brand_id && catIDs[0] == alllibrarybrand[j].therapeutic_area_id && alllibrarybrand[j].therapeutic_area_id == id) {
                                alllibrarybrand[j].selected = true
                                selectedlist.push(alllibrarybrand[j])
                            }
                        }
                    } else {
                        //alert("rk")
                        let idsss = []
                        let idss2 = []
                        for (let k = 0; k < alllibrarybrand.length; k++) {
                            if (alllibrarybrand[k].therapeutic_area_id == id) {
                                for (let j = 0; j < alllibraryindication.length; j++) {
                                    if (alllibrarybrand[k].brand_id == alllibraryindication[j].brand_id) {
                                        if (!idsss.includes(alllibraryindication[j].indication_id)) {
                                            idsss.push(alllibraryindication[j].indication_id)
                                            list.push(alllibraryindication[j])
                                        }

                                    }
                                    if (catIDs[2] == alllibraryindication[j].indication_id && alllibrarybrand[k].brand_id == alllibraryindication[j].brand_id) {
                                        if (!idss2.includes(alllibraryindication[j].indication_id)) {
                                            idss2.push(alllibraryindication[j].indication_id)
                                            alllibraryindication[j].selected = true;
                                            selectedlist.push(alllibraryindication[j])
                                        }

                                    }
                                }
                            }
                        }
                    }

                }
                if (type == 'b') {
                    for (let j = 0; j < alllibraryindication.length; j++) {
                        if (alllibraryindication[j].brand_id == id) {
                            list.push(alllibraryindication[j])
                        }
                        if (catIDs[2] == alllibraryindication[j].indication_id && catIDs[1] == alllibraryindication[j].brand_id && alllibraryindication[j].brand_id == id) {
                            alllibraryindication[j].selected = true;
                            selectedlist.push(alllibraryindication[j])
                        }
                    }

                }
            }
        }
        setactiveBrand(filterArray(list, "b"))
        setselectedBrand(filterArray(selectedlist, "b"))
        setactiveIndication(filterArray(list, "i"))
        setselectedIndication(selectedlist)
    }
    const setactiveCatlevel2 = (index, tid, id, type) => {
        setactiveLevel2('level2' + index)
        setactiveidforClasslevel2(id + "level2" + index)
        setactivebrandid(id)
        let list = [];
        let selectedlist = [];
        let idss = []
        let idss2 = []

        for (let i = 0; i < libraryArray[index]['categories'].length; i++) {
            let lindex = (libraryArray[index]['categories'][i]).toString();
            const catIDs = lindex.split("_");
            if (catIDs.length > 0) {
                if (type == 'b') {
                    for (let j = 0; j < alllibraryindication.length; j++) {
                        if (alllibraryindication[j].brand_id == id) {
                            if (!idss2.includes(alllibraryindication[j].indication_id)) {
                                list.push(alllibraryindication[j])
                                idss2.push(alllibraryindication[j].indication_id)
                            }
                        }
                        if (catIDs[2] == alllibraryindication[j].indication_id && catIDs[1] == alllibraryindication[j].brand_id && alllibraryindication[j].brand_id == id) {
                            alllibraryindication[j].selected = true;
                            if (!idss.includes(alllibraryindication[j].indication_id)) {
                                selectedlist.push(alllibraryindication[j])
                                idss.push(alllibraryindication[j].indication_id)
                            }
                        }
                    }

                }
            }
        }
        setactiveIndicationLevel3(list)
        setselectedIndicationLevel3(selectedlist)
    }
    

    const removeCat = (id, type, index, indexArray) => {
        //alert(id);
        //return;
        let newArr = [...libraryArray];
        if (type == 't') {
            setactiveidforClasslevel2('')
            setactiveidforClass('')
            setactiveLevel2('')
            setactiveLevel1('')
            let tharea = newArr[index]['therapeuticareas'];
            let brandarea = newArr[index]['brand'];
            let indicationarea = newArr[index]['indication'];
            let categories = newArr[index]['categories'];
            let bid = []
            let iid = [];
            let tid = [];
            let cattid = [];

            for (let j = 0; j < categories.length; j++) {
                let cati = categories[j].toString()
                const catIDs = cati.split("_");
                if (catIDs[0]) {
                    if (catIDs[0] == id) {
                        cattid.push(j)
                        //tid.push(parseInt(catIDs[0]))
                        tid.push(catIDs[0])

                        if (catIDs[1]) {
                            //bid.push(parseInt(catIDs[1]))
                            bid.push(catIDs[1])
                        }
                        if (catIDs[2]) {
                            //iid.push(parseInt(catIDs[2]))
                            iid.push(catIDs[2])
                        }
                    }
                }
            }
            let theraupaticarea = tharea.filter(function (obj) { return tid.indexOf(obj) == -1; });
            let brand = brandarea.filter(function (obj) { return bid.indexOf(obj) == -1; });
            let indication = indicationarea.filter(function (obj) { return iid.indexOf(obj) == -1; });

            newArr[index]['brand'] = brand
            newArr[index]['indication'] = indication
            newArr[index]['therapeuticareas'] = theraupaticarea
            let valuesArr = categories;
            let removeValFromIndex = cattid;
            for (var i = removeValFromIndex.length - 1; i >= 0; i--) {
                valuesArr.splice(removeValFromIndex[i], 1)
            }
            newArr[index]['categories'] = valuesArr
            if (id == 0) {
                newArr[index]['therapeuticareas'].splice(indexArray, 1);
            }
        }
        if (type == 'bm') {
            setactiveLevel2('')
            setactiveLevel1('')
            setactiveidforClasslevel2('')
            setactiveidforClass('')
            let brandarea = newArr[index]['brand'];
            let indicationarea = newArr[index]['indication'];
            let categories = newArr[index]['categories'];
            let bid = []
            let iid = [];
            let tid = [];
            let cattid = [];

            for (let j = 0; j < categories.length; j++) {
                let cati = categories[j].toString()
                const catIDs = cati.split("_");
                if (catIDs[1]) {
                    if (catIDs[1] == id) {
                        cattid.push(j)
                        if (catIDs[1]) {
                            bid.push(catIDs[1])
                        }
                        if (catIDs[2]) {
                            iid.push(catIDs[2])
                        }
                    }
                }
            }
            let brand = brandarea.filter(function (obj) { return bid.indexOf(obj) == -1; });
            let indication = indicationarea.filter(function (obj) { return iid.indexOf(obj) == -1; });
            newArr[index]['brand'] = brand
            newArr[index]['indication'] = indication
            let valuesArr = categories;
            let removeValFromIndex = cattid;
            for (let i = removeValFromIndex.length - 1; i >= 0; i--) {
                valuesArr.splice(removeValFromIndex[i], 1)
            }
            newArr[index]['categories'] = valuesArr
            if (id == 0) {
                newArr[index]['brand'].splice(indexArray, 1);
            }

        }
        if (type == 'im') {
            setactiveLevel2('')
            setactiveLevel1('')
            setactiveidforClasslevel2('')
            setactiveidforClass('')
            let indicationarea = newArr[index]['indication'];
            let categories = newArr[index]['categories'];
            let bid = []
            let iid = [];
            let tid = [];
            let cattid = [];

            for (let j = 0; j < categories.length; j++) {
                let cati = categories[j].toString()
                const catIDs = cati.split("_");
                if (catIDs[2]) {
                    if (catIDs[2] == id) {
                        cattid.push(j)
                        if (catIDs[2]) {
                            iid.push(catIDs[2])
                        }
                    }
                }
            }
            let indication = indicationarea.filter(function (obj) { return iid.indexOf(obj) == -1; });
            newArr[index]['indication'] = indication
            let valuesArr = categories;
            let removeValFromIndex = cattid;
            for (let i = removeValFromIndex.length - 1; i >= 0; i--) {
                valuesArr.splice(removeValFromIndex[i], 1)
            }
            newArr[index]['categories'] = valuesArr
            if (id == 0) {
                newArr[index]['indication'].splice(indexArray, 1);
            }
        }
        setLibraryArray(newArr)
    }

    const removeLevel2 = (index, type, selected, tid, id) => {
        let newArr = [...libraryArray];
        if (type == 'b') {
            setactiveLevel2('')
            setactiveidforClasslevel2('')
            // setactiveLevel1('')
            let brandarea = newArr[index]['brand'];
            let indicationarea = newArr[index]['indication'];
            let categories = newArr[index]['categories'];
            let bid = []
            let iid = [];
            let cattid = [];

            for (let j = 0; j < categories.length; j++) {
                let cati = categories[j].toString()
                const catIDs = cati.split("_");
                if (catIDs[1]) {
                    if (catIDs[1] == id) {
                        categories[j] = catIDs[0]
                        if (catIDs[1]) {
                            bid.push(catIDs[1])
                        }
                        if (catIDs[2]) {
                            iid.push(catIDs[2])
                        }
                    }
                }
            }
            let brand = brandarea.filter(function (obj) { return bid.indexOf(obj) == -1; });
            let indication = indicationarea.filter(function (obj) { return iid.indexOf(obj) == -1; });
            newArr[index]['brand'] = brand
            newArr[index]['indication'] = indication

            newArr[index]['categories'] = categories
            let selbrand = [...selectedBrand]
            selbrand.splice(tid, 1);
            setselectedBrand(selbrand)
            if (id == 0) {
                newArr[index]['brand'].splice(tid, 1);
            }

        }
        if (type == 'i') {
            setactiveLevel2('')
            setactiveidforClasslevel2('')
            // setactiveLevel1('')
            // let brandarea = newArr[index]['brand'];
            let indicationarea = newArr[index]['indication'];
            let categories = newArr[index]['categories'];
            // let bid = []
            let iid = [];
            let cattid = [];

            for (let j = 0; j < categories.length; j++) {
                let cati = categories[j].toString()
                const catIDs = cati.split("_");
                if (catIDs[2]) {
                    if (catIDs[2] == id) {
                        categories[j] = "_" + catIDs[1]
                        if (catIDs[2]) {
                            iid.push(catIDs[2])
                        }
                    }
                }
            }
            let indication = indicationarea.filter(function (obj) { return iid.indexOf(obj) == -1; });
            newArr[index]['indication'] = indication

            newArr[index]['categories'] = categories
            let selindication = [...selectedIndication]
            selindication.splice(tid, 1);
            setselectedIndication(selindication)
            if (id == 0) {
                newArr[index]['indication'].splice(tid, 1);
            }
        }
        setLibraryArray(newArr)

    }

    const removeLevel3 = (index, selected, tid, id) => {
        let newArr = [...libraryArray];
        let indicationarea = newArr[index]['indication'];
        let categories = newArr[index]['categories'];
        // let bid = []
        let iid = [];
        let cattid = [];

        for (let j = 0; j < categories.length; j++) {
            let cati = categories[j].toString()
            const catIDs = cati.split("_");
            if (catIDs[2]) {
                if (catIDs[2] == id) {
                    categories[j] = catIDs[0] + "_" + catIDs[1]
                    if (catIDs[2]) {
                        iid.push(catIDs[2])
                    }
                }
            }
        }
        let indication = indicationarea.filter(function (obj) { return iid.indexOf(obj) == -1; });
        newArr[index]['indication'] = indication

        newArr[index]['categories'] = categories
        let selindication = [...selectedIndicationLevel3]
        selindication.splice(tid, 1);
        setselectedIndicationLevel3(selindication)
        if (id == 0) {
            newArr[index]['indication'].splice(tid, 1);
        }

        setLibraryArray(newArr)
    }
    return (
        <>
            <Modal open={openLibraryModal} onClose={handleLibraryClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleLibraryClose()}></button>
                    </Grid>
                    <Grid className="modal-body">

                        {libraryText.map(text => {
                            return (<>
                                <div className="m20">
                                    <Alert severity="warning">{text.value}</Alert>
                                </div>
                            </>)
                        })}
                    </Grid>

                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handleLibraryClose()} className="modalBtn">OK</button>
                    </Grid>
                </Grid>
            </Modal>
            <Modal open={openLibraryModalActive} onClose={handleLibraryClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => setopenLibraryModalActive(false)}></button>
                    </Grid>
                    <Grid className="modal-body">

                        {/* {libraryText.map(text => {
                            return (<> */}
                        <div className="m20">
                            <Alert severity="warning">Warning! Some of your videos are not tagged to any library, are you sure you want to proceed?</Alert>
                        </div>
                        {/* </>)
                        })} */}
                    </Grid>

                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={(e) => {
                            props.SetlibraryActive(3)
                            setopenLibraryModalActive(false)
                            setopenLibraryModal(true);
                            setlibraryText([{ value: 'Success! Libraries are now active for your users.' }]);
                            // setlibOff(2)
                        }
                        } className="modalBtn">OK</button>
                        <button type="button" onClick={(e) => {
                            props.SetlibraryActive(2)
                            setopenLibraryModalActive(false)
                            // setlibOff(2)
                        }
                        } className="modalBtn">Cancel</button>
                    </Grid>
                </Grid>
            </Modal>
            <Grid container className='content'>
                <Grid container item xs={12} lg={12} spacing={2}>
                    <Grid container className='grid_row'>
                        <FormControl component="fieldset" className='grid_row'>
                            <FormLabel component="label"> Activate and/or edit your libraries below</FormLabel>
                        </FormControl>
                        <FormControl component="fieldset" className='grid_row'>
                            <FormLabel component="p" className='textNormal'>Edit the name and image of the libraries for external users.</FormLabel>
                        </FormControl>
                    </Grid>
                    {props.libraryActive == 1 ? <Grid container className='grid_row'>
                        <Switch
                            className='libOffStch'
                            // checked={stateSwitch}
                            onChange={(e) => {
                                props.SetlibraryActive(2)
                                // setlibOff(2)
                            }
                            }
                            name="checkedA"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <span className='hideTxt'>Libraries Off</span>
                    </Grid> : ''}
                    {props.libraryActive == 2 ? <Grid container className='grid_row'>
                        <Switch
                            className='pending-libraries'
                            // checked={stateSwitch}
                            onChange={(e) => {
                                // setlibraryText([{ value: 'Libraries are turned off.' }]);
                                setopenLibraryModalActive(true)
                                props.SetlibraryActive(3)
                            }}

                            name="checkedA"

                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <span className='hideTxt'>Libraries Pending</span>
                    </Grid> : ''}
                    {props.libraryActive == 3 ?
                        <Grid container className='grid_row'>
                            <Switch
                                className='libraries-ON'
                                defaultChecked
                                // checked={stateSwitchPending}
                                onChange={(e) => {
                                    setopenLibraryModal(true);
                                    setlibraryText([{ value: 'Libraries are turned off.' }]);
                                    props.SetlibraryActive(1)
                                }}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <span className='hideTxt'>Libraries On</span>
                        </Grid> : ''}
                    {props.libraryActive != 1 ?
                        <>
                            <Grid container className='grid_row'>
                                <Paper className='libSmTxt'>You can now define your libraries and tag your videos to the libraries.</Paper>
                            </Grid>
                            {props.libraryActive == 3 ?
                                <Grid container className='grid_row ptb-10'>
                                    <Switch
                                        className='hideTbaChk'
                                        checked={props.tatoogle ? props.tatoogle : false}
                                        onChange={handlelibHTA}
                                        name="checkedA"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    <span className='hideTxt'>Hide TAs, Brand and Indications</span>
                                </Grid>
                                : ""}
                            {hideshowAdd && <Grid container className='grid_row itemAdd'>
                                <img src={require('../../../public/img/Add@3x.png')} className='itemAddIcon' alt='img' />
                                <span onClick={() => handleAddLibrary()}
                                >Add Library</span>
                            </Grid>}
                            <Grid container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={4}>

                                {libraryArray && libraryArray.length > 0 && libraryArray.map((arr, index) =>{
                                    return (
                                    <Grid item xs={12} sm={3} md={3} lg={3}>

                                        <Grid className='grid_row uploadLibOuter'>
                                            <Grid className='grid_row'>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"

                                                    placeholder="Insert Library Name"
                                                    className='updLibTxt'
                                                    value={arr.library_name}
                                                    onChange={(e) => {
                                                        let newArr = [...libraryArray];
                                                        newArr[index]['library_name'] = e.target.value;
                                                        setLibraryArray(newArr)
                                                    }
                                                    }
                                                />
                                            </Grid>
                                            {((!arr.thumbnail_url || arr.thumbnail_url == '') ) ? <span className='uploadLib' onClick={(e) => {
                                                let imgSelec = [...hideshowImageelector];
                                                imgSelec[index] = true;
                                                sethideshowImageelector(imgSelec)

                                            }
                                            } >Upload Library Photo</span> :
                                                <div className='lib-img-div'>
                                                    {arr.staticimage && arr.library_id ? <img class="imageStyle 1" onChange={(event) => { }} src={arr.thumbnail_url} alt='events icon' onClick={(e) => {
                                                        let imgSelec = [...hideshowImageelector];
                                                        imgSelec[index] = true;
                                                        sethideshowImageelector(imgSelec)
                                                    }} /> : 
                                                    <span className=''>

                                                    {arr.library_id ? <img class={"imageStyle 2"+ arr.library_id} onChange={(event) => { }} src={imagearray['image'+arr.library_id]} alt='events icon' onClick={(e) => {
                                                        let imgSelec = [...hideshowImageelector];
                                                        imgSelec[index] = true;
                                                        sethideshowImageelector(imgSelec)
                                                    }} /> :<img class="imageStyle 1" onChange={(event) => { }} src={arr.thumbnail_url} alt='events icon' onClick={(e) => {
                                                        let imgSelec = [...hideshowImageelector];
                                                        imgSelec[index] = true;
                                                        sethideshowImageelector(imgSelec)
                                                    }} />}
                                                    </span>

                                                    }
                                                </div>}
                                            {hideshowImageelector[index] === true ?
                                                <>
                                                    <Grid className='triangle-left'></Grid>
                                                    <Grid className={index === 3 ? 'uploadLibInnerRight': 'uploadLibInner'}>
                                                        <Grid className='uploadHdSec'>
                                                            Please click image to select your choice.
                                                            <button className='closeModal' onClick={(e) => {
                                                                let imgSelec = [...hideshowImageelector];
                                                                imgSelec[index] = false;
                                                                sethideshowImageelector(imgSelec)
                                                            }
                                                            }></button>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12} lg={7}>
                                                                <ul className='uploadLiblist'>
                                                                    <Grid className='library-icon-div flex-div' item xs={12} lg={12}>
                                                                        <Grid item xs={12} lg={4}>
                                                                            <li className='uploadLiblistInner'>
                                                                                <img onClick={(event) => {
                                                                                    let newArr = [...libraryArray];
                                                                                    newArr[index]['thumbnail_url'] = educational;
                                                                                    newArr[index]['staticimage'] = true;

                                                                                    setLibraryArray(newArr)
                                                                                    let imgSelec = [...hideshowImageelector];
                                                                                    imgSelec[index] = false;
                                                                                    sethideshowImageelector(imgSelec)
                                                                                }} src={educational} alt='education icon' />
                                                                            </li>
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={4}>
                                                                            <li className='uploadLiblistInner'>
                                                                                <img onClick={(event) => {
                                                                                    let newArr = [...libraryArray];
                                                                                    newArr[index]['thumbnail_url'] = events;
                                                                                    newArr[index]['staticimage'] = true;
                                                                                    setLibraryArray(newArr)
                                                                                    let imgSelec = [...hideshowImageelector];
                                                                                    imgSelec[index] = false;
                                                                                    sethideshowImageelector(imgSelec)
                                                                                }} src={events} alt='events icon' />
                                                                            </li>
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={4}>
                                                                            <li className='uploadLiblistInner'>
                                                                                <img onClick={(event) => {
                                                                                    let newArr = [...libraryArray];
                                                                                    newArr[index]['thumbnail_url'] = promotional;
                                                                                    newArr[index]['staticimage'] = true;
                                                                                    setLibraryArray(newArr)
                                                                                    let imgSelec = [...hideshowImageelector];
                                                                                    imgSelec[index] = false;
                                                                                    sethideshowImageelector(imgSelec)
                                                                                }} src={promotional} alt='promotional icon' />
                                                                            </li>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ul>
                                                            </Grid>
                                                            <Grid item xs={12} lg={5} className='library-div-border text-center'>
                                                                <img className='empty-img' src={require('../../../public/img/empty.png')} alt='empty icon' />
                                                                {/* <FormControl component="fieldset" className='formControl'>

                                                                    <label htmlFor={"contained-button-file" + index}>
                                                                        <input
                                                                            accept="image/*"
                                                                            className='hidden'
                                                                            id={"contained-button-file" + index}
                                                                            name="library_photo[]"
                                                                            type="file"
                                                                            onInput={(e) => imgPreviewHandler(e.target.files[0], index)}
                                                                        // onChange={ imgPreviewHandler(index) }
                                                                        />
                                                                        <Button className="speaker_upload_btn upload_your_own" component="span">
                                                                            Upload Your Own
                                                                        </Button>
                                                                    </label>
                                                                </FormControl> */}
                                                            </Grid>
                                                            <Grid item xs={12} lg={7}></Grid>
                                                            <Grid item xs={12} lg={5}>
                                                                <FormControl component="fieldset" className='formControl'>

                                                                    <label htmlFor={"contained-button-file" + index}>
                                                                        <input
                                                                            accept="image/*"
                                                                            className='hidden'
                                                                            id={"contained-button-file" + index}
                                                                            name="library_photo[]"
                                                                            type="file"
                                                                            onInput={(e) => imgPreviewHandler(e.target.files[0], index)}
                                                                        // onChange={ imgPreviewHandler(index) }
                                                                        />
                                                                        <Button className="className='speaker_upload_btn upload_your_own" component="span">
                                                                            Upload Your Own
                                                                        </Button>
                                                                    </label>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                : null}
                                            <Grid className='grid_row libIncSec'>
                                                <Switch
                                                    checked={!arr.status ? false : true}
                                                    className='libInactChk'
                                                    // onChange={handlelibStatus}
                                                    onChange={(e) => {
                                                        if (libraryArray[index]['library_name'] != '' && libraryArray[index]['thumbnail_url'] != '') {
                                                            let newArr = [...libraryArray];
                                                            newArr[index]['status'] = !newArr[index]['status'];
                                                            setLibraryArray(newArr)
                                                        } else {
                                                            setopenLibraryModal(true);
                                                            if (libraryArray[index]['library_name'] == '' && libraryArray[index]['thumbnail_url'] == '') {
                                                                setlibraryText([{ value: "Library name cannot be empty." }, { value: "Library must have a photo." }]);
                                                            }
                                                            if (libraryArray[index]['library_name'] == '' && libraryArray[index]['thumbnail_url'] != '') {
                                                                setlibraryText([{ value: "Library name cannot be empty." }]);
                                                            }
                                                            if (libraryArray[index]['library_name'] != '' && libraryArray[index]['thumbnail_url'] == '') {
                                                                setlibraryText([{ value: "Library must have a photo." }]);
                                                            }

                                                        }
                                                    }
                                                    }
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                <span className='libIncSpan'>Library {!arr.status ? "Inactive" : "Active"}</span>
                                                <div>
                                                    <DeleteOutlinedIcon onClick={() => handleDeleteLibrary(index, arr)} className='deleteOutlineIcon' />
                                                </div>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                )
                                                })}
                            </Grid>
                            <TabContext value={value}>
                                <AppBar position="static" className='appbarSec'>
                                    {libraryArray && libraryArray.length > 0 ?
                                        <TabList onChange={handleChangeTab} aria-label="simple tabs example">
                                            {libraryArray.map((arr, index) => (
                                                <Tab className='appTab' label={arr.library_name} value={index} />
                                            ))}
                                        </TabList>
                                        : null}
                                </AppBar>
                                {libraryArray && libraryArray.length > 0 && libraryArray.map((arr, index) => (
                                    <>
                                        <TabPanel value={index} className='p-10-0 tabStaticWidth'>
                                            <Grid xs={12}>
                                                <label>Edit Drop Down Menu</label>
                                            </Grid>
                                            <Grid container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                                spacing={0}>
                                                <Grid item xs={1}>
                                                    <label>Level 1</label>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl className=' ' sx={{ pt: '10%' }}>
                                                        <Select
                                                            className='selectBx'
                                                            value={librarythraupatiAreacheck[index]}
                                                            disabled={librarythraupatiAreacheck[index] != undefined && librarythraupatiAreacheck[index] != '' ? true : false}
                                                            onChange={(e) => {
                                                                let newArr = [...librarythraupatiAreacheck];
                                                                newArr[index] = e.target.value
                                                                setlibrarythraupatiAreacheck(newArr)
                                                                sethideshowlevel({ ...hideshowlevel, [index + '_level2']: false })
                                                            }
                                                            }
                                                            label="Select Therapeutic Areas"
                                                            placeholder="Select Therapeutic Areas"
                                                            disableUnderline='true'>
                                                            {fixedMenuDropDown.length > 0 ? fixedMenuDropDown.map((therapeuticArea, i) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={therapeuticArea.id}
                                                                    >
                                                                        {therapeuticArea.name}
                                                                    </MenuItem>
                                                                )
                                                            }) : ""}
                                                        </Select>
                                                    </FormControl>

                                                </Grid>
                                                <Grid item xs={1}>
                                                    <button onClick={() => refreshIndec(index, "level1")} className='singleBtn' ><AutorenewIcon /></button>
                                                </Grid>

                                                <Grid item xs={hideshowlevel[index + "_level2"] && hideshowlevel[index + "_level2"] ? 1 : 2}>

                                                    {(!hideshowlevel[index + "_level2"]) && librarythraupatiAreacheck[index] && librarythraupatiAreacheck[index] != '' && librarythraupatiAreacheck[index] == 1 &&
                                                        <div className='itemAdd'>
                                                            <img
                                                                onClick={() => showhideLevel(index, "level2")}
                                                                src={require('../../../public/img/Add@3x.png')}
                                                                className='' alt='img'
                                                            />
                                                            <span onClick={() => showhideLevel(index, "level2")}>Add Level 2</span>
                                                        </div>
                                                    }
                                                    {hideshowlevel[index + "_level2"] ? <label>Level 2</label> : ""}
                                                </Grid>
                                                {hideshowlevel[index + "_level2"] && <Grid item xs={2}>
                                                    <FormControl >
                                                        <Select
                                                            className='selectBx'
                                                            value={librarybrandcheck[index]}
                                                            disabled={librarybrandcheck[index] ? true : false}
                                                            onChange={(e) => {
                                                                let newArr = [...librarybrandcheck];
                                                                newArr[index] = e.target.value
                                                                setlibrarybrandcheck(newArr)
                                                                sethideshowlevel({ ...hideshowlevel, [index + '_level3']: false })

                                                            }
                                                            }
                                                            label="Select Therapeutic Areas"
                                                            placeholder="Select Therapeutic Areas"
                                                            disableUnderline='true'>
                                                            {fixedMenuDropDown.length > 0 ? fixedMenuDropDown.map((therapeuticArea, i) => {
                                                                let disableArray = [];
                                                                if (librarythraupatiAreacheck[index] == 1) {
                                                                    disableArray.push(1)
                                                                }
                                                                else if (librarythraupatiAreacheck[index] == 2) {
                                                                    disableArray.push(1)
                                                                    disableArray.push(2)
                                                                }
                                                                else if (librarythraupatiAreacheck[index] == 3) {
                                                                    disableArray.push(1)
                                                                    disableArray.push(2)
                                                                    disableArray.push(3)
                                                                }
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        disabled={disableArray.includes(therapeuticArea.id) ? true : false}
                                                                        value={therapeuticArea.id}
                                                                    >
                                                                        {therapeuticArea.name}
                                                                    </MenuItem>
                                                                )
                                                            }) : ""}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>}
                                                {hideshowlevel[index + "_level2"] && <Grid item xs={1}>
                                                    <button className='singleBtn' onClick={() => refreshIndec(index, "level2")}><AutorenewIcon /></button>
                                                </Grid>}



                                                <Grid item xs={hideshowlevel[index + "_level3"] && hideshowlevel[index + "_level3"] ? 1 : 2}>
                                                    {(hideshowlevel[index + "_level3"] != undefined && !hideshowlevel[index + "_level3"]) && librarythraupatiAreacheck[index] && librarythraupatiAreacheck[index] != '' && librarybrandcheck[index] == 2 &&
                                                        <div className='itemAdd'>
                                                            <img onClick={() => showhideLevel(index, "level3")}
                                                                src={require('../../../public/img/Add@3x.png')}
                                                                className='' alt='img' />
                                                            <span onClick={() => showhideLevel(index, "level3")}>Add Level 3</span>
                                                        </div>
                                                    }
                                                    {hideshowlevel[index + "_level3"] ? <label>Level 3</label> : ""}
                                                </Grid>
                                                {hideshowlevel[index + "_level3"] && <Grid item xs={2}>
                                                    <FormControl className='formControl'>
                                                        <Select
                                                            className='selectBx'
                                                            value={libraryindicationcheck[index]}
                                                            disabled={libraryindicationcheck[index] ? true : false}
                                                            onChange={(e) => {
                                                                let newArr = [...libraryindicationcheck];
                                                                newArr[index] = e.target.value
                                                                setlibraryindicationcheck(newArr)
                                                            }
                                                            }
                                                            label="Select Therapeutic Areas"
                                                            placeholder="Select Therapeutic Areas"
                                                            disableUnderline='true'>
                                                            {fixedMenuDropDown.length > 0 ? fixedMenuDropDown.map((therapeuticArea, i) => {
                                                                let disableArray = [];
                                                                if (librarybrandcheck[index] == 1) {
                                                                    disableArray.push(1)
                                                                }
                                                                else if (librarybrandcheck[index] == 2) {
                                                                    disableArray.push(1)
                                                                    disableArray.push(2)
                                                                }
                                                                else if (librarybrandcheck[index] == 3) {
                                                                    disableArray.push(1)
                                                                    disableArray.push(2)
                                                                    disableArray.push(3)
                                                                }
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={therapeuticArea.id}
                                                                        disabled={disableArray.includes(therapeuticArea.id) ? true : false}
                                                                    >
                                                                        {therapeuticArea.name}
                                                                    </MenuItem>
                                                                )
                                                            }) : ""}
                                                        </Select>
                                                    </FormControl>



                                                </Grid>}
                                                {hideshowlevel[index + "_level3"] && <Grid item xs={1}>
                                                    <button className='singleBtn' onClick={() => refreshIndec(index, "level3")}><AutorenewIcon /></button>

                                                </Grid>}



                                                <Grid container>
                                                    <Grid item xs={3} sm={1} md={1} lg={1}>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={2} lg={2}>
                                                        {librarythraupatiAreacheck[index] == 1 && arr.therapeuticareas.length > 0 ? arr.therapeuticareas.map((tare, tid) => {
                                                            return (
                                                                <>
                                                                    <Grid container
                                                                        direction="row"
                                                                        justify="flex-start"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item xs={9}>
                                                                            <FormControl className='formControl selectdropdown'>
                                                                                <Select
                                                                                    className={activeidforClass == arr.therapeuticareas[tid] + 'level1' + index ? "activeclass selectBx" : "selectBx"}
                                                                                    fullWidth
                                                                                    value={arr.therapeuticareas[tid] ? arr.therapeuticareas[tid] : ""}
                                                                                    disabled={arr.therapeuticareas[tid] ? true : false}
                                                                                    onChange={(e) => {
                                                                                        let newArr = [...libraryArray];
                                                                                        newArr[index]['therapeuticareas'][tid] = e.target.value;

                                                                                        newArr[index]['categories'].push(e.target.value);
                                                                                        setLibraryArray(newArr)
                                                                                    }
                                                                                    }
                                                                                    label="Select Therapeutic Areas"
                                                                                    placeholder="Select Therapeutic Areas"
                                                                                    disableUnderline='true'>
                                                                                    {alllibrarythraupatiArea.length > 0 ? alllibrarythraupatiArea.map((alltherapeuticArea, alltid) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                disabled={arr.therapeuticareas.includes(alltherapeuticArea.therapeutic_area_id) ? true : false}
                                                                                                key={alltid}
                                                                                                value={alltherapeuticArea.therapeutic_area_id}
                                                                                            >
                                                                                                {alltherapeuticArea.therapeutic_area_name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    }) : ""}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={1}>
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    removeCat(arr.therapeuticareas[tid], 't', index, tid)
                                                                                }}
                                                                                className='iconImg removeIcon' ></button>
                                                                        </Grid>
                                                                        {librarybrandcheck[index] && librarybrandcheck[index] != '' && <Grid item xs={1} style={{ paddingTop: '10px', paddingLeft: '5px', marginLeft: '10px' }}>
                                                                            <img onClick={(e) => {
                                                                                setactiveIndex(index)
                                                                                setactiveTid(tid)
                                                                                setactiveCat(index, tid, arr.therapeuticareas[tid], 't')
                                                                            }}
                                                                                src={require('../../../public/img/Add@3x.png')} className='addImg2' alt='img' />
                                                                        </Grid>}
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }) : ""}
                                                        {librarythraupatiAreacheck[index] == 2 && arr.brand.length > 0 ? arr.brand.map((tare, tid) => {
                                                            return (
                                                                <>
                                                                    <Grid container
                                                                        direction="row"
                                                                        justify="flex-start"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item xs={9}>
                                                                            <FormControl className='formControl'>
                                                                                <Select
                                                                                    className={activeidforClass == arr.brand[tid] + 'level1' + index ? "activeclass  selectBx" : "selectBx"}
                                                                                    value={arr.brand[tid]}
                                                                                    disabled={arr.brand[tid] ? true : false}
                                                                                    onChange={(e) => {
                                                                                        let newArr = [...libraryArray];
                                                                                        newArr[index]['brand'][tid] = e.target.value;
                                                                                        newArr[index]['categories'][tid] = "_" + e.target.value;
                                                                                        setLibraryArray(newArr)
                                                                                    }
                                                                                    }
                                                                                    label="Select Therapeutic Areas"
                                                                                    placeholder="Select Therapeutic Areas"
                                                                                    disableUnderline='true'>
                                                                                    {alllibrarybrand.length > 0 ? alllibrarybrand.map((alltherapeuticArea, alltid) => {

                                                                                        return (
                                                                                            <MenuItem
                                                                                                disabled={arr.brand.includes(alltherapeuticArea.brand_id)}
                                                                                                key={alltid + "brand"}
                                                                                                value={alltherapeuticArea.brand_id}
                                                                                            >
                                                                                                {alltherapeuticArea.brand_name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    }) : ""}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={1}>
                                                                            <button onClick={(e) => {
                                                                                removeCat(arr.brand[tid], 'bm', index, tid)
                                                                            }} className='iconImg removeIcon' ></button>
                                                                        </Grid>
                                                                        {librarybrandcheck[index] && librarybrandcheck[index] != '' && <Grid item xs={1} style={{ paddingTop: '10px', paddingLeft: '5px', marginLeft: '10px' }}>
                                                                            <img onClick={(e) => {
                                                                                setactiveIndex(index)
                                                                                setactiveTid(tid)
                                                                                setactiveCat(index, tid, arr.brand[tid], "b")
                                                                            }}
                                                                                src={require('../../../public/img/Add@3x.png')} className='addImg2' alt='img' />
                                                                        </Grid>}
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }) : ""}
                                                        {librarythraupatiAreacheck[index] == 3 && arr.indication.length > 0 ? arr.indication.map((tare, tid) => {
                                                            return (
                                                                <>
                                                                    <Grid container
                                                                        direction="row"
                                                                        justify="flex-start"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item xs={9}>
                                                                            <FormControl className='formControl'>
                                                                                <Select
                                                                                    className={activeidforClass == arr.indication[tid] + 'level1' + index ? "activeclass selectBx" : "selectBx"}
                                                                                    value={arr.indication[tid]}
                                                                                    disabled={arr.indication[tid] ? true : false}
                                                                                    onChange={(e) => {
                                                                                        let newArr = [...libraryArray];
                                                                                        newArr[index]['indication'][tid] = e.target.value;
                                                                                        newArr[index]['categories'][tid] = "__" + e.target.value;
                                                                                        setLibraryArray(newArr)
                                                                                    }
                                                                                    }
                                                                                    label="Select Therapeutic Areas"
                                                                                    placeholder="Select Therapeutic Areas"
                                                                                    disableUnderline='true'>
                                                                                    {alllibraryindication.length > 0 ? alllibraryindication.map((alltherapeuticArea, alltid) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                disabled={arr.indication.includes(alltherapeuticArea.indication_id) ? true : false}
                                                                                                key={alltid + "indication"}
                                                                                                value={alltherapeuticArea.indication_id}
                                                                                            >
                                                                                                {alltherapeuticArea.indication_name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    }) : ""}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={1}>
                                                                            <button onClick={(e) => {
                                                                                removeCat(arr.indication[tid], 'im', index, tid)
                                                                            }} className='iconImg removeIcon' ></button>
                                                                        </Grid>
                                                                        {librarybrandcheck[index] && librarybrandcheck[index] != '' && <Grid item xs={1} style={{ paddingTop: '10px', paddingLeft: '5px', marginLeft: '10px' }}>
                                                                            <img onClick={(e) => {
                                                                                setactiveIndex(index)
                                                                                setactiveTid(tid)
                                                                                setactiveCat(index, tid, arr.indication[tid], "i")
                                                                            }
                                                                            } src={require('../../../public/img/Add@3x.png')} className='addImg2' alt='img' />
                                                                        </Grid>}
                                                                    </Grid>
                                                                </>

                                                            )
                                                        }) : ""}
                                                        {librarythraupatiAreacheck[index] != undefined && librarythraupatiAreacheck[index] == 1 && libraryArray[index]['therapeuticareas'].length < alllibrarythraupatiArea.length ?
                                                            <div className='itemAdd'
                                                                onClick={(e) => {
                                                                    let newArr = [...libraryArray];
                                                                    if (librarythraupatiAreacheck[index] == 1) {
                                                                        newArr[index]['therapeuticareas'].push(0);
                                                                    } else if (librarythraupatiAreacheck[index] == 2) {
                                                                        newArr[index]['brand'].push(0);
                                                                    }
                                                                    else if (librarythraupatiAreacheck[index] == 3) {
                                                                        newArr[index]['indication'].push(0);
                                                                    }
                                                                    setLibraryArray(newArr)
                                                                }
                                                                }
                                                            >
                                                                <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                                                <span>New Item</span>
                                                            </div>
                                                            :
                                                            librarythraupatiAreacheck[index] == 2 && libraryArray[index]['brand'].length < alllibrarybrand.length ?
                                                                <div className='itemAdd'
                                                                    onClick={(e) => {
                                                                        let newArr = [...libraryArray];
                                                                        if (librarythraupatiAreacheck[index] == 1) {
                                                                            newArr[index]['therapeuticareas'].push(0);
                                                                        } else if (librarythraupatiAreacheck[index] == 2) {
                                                                            newArr[index]['brand'].push(0);
                                                                        }
                                                                        else if (librarythraupatiAreacheck[index] == 3) {
                                                                            newArr[index]['indication'].push(0);
                                                                        }
                                                                        setLibraryArray(newArr)
                                                                    }
                                                                    }
                                                                >
                                                                    <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                                                    <span>New Item</span>
                                                                </div> :
                                                                librarythraupatiAreacheck[index] == 3 && libraryArray[index]['indication'].length < alllibraryindication.length ?
                                                                    <div className='itemAdd'
                                                                        onClick={(e) => {
                                                                            let newArr = [...libraryArray];
                                                                            if (librarythraupatiAreacheck[index] == 1) {
                                                                                newArr[index]['therapeuticareas'].push(0);
                                                                            } else if (librarythraupatiAreacheck[index] == 2) {
                                                                                newArr[index]['brand'].push(0);
                                                                            }
                                                                            else if (librarythraupatiAreacheck[index] == 3) {
                                                                                newArr[index]['indication'].push(0);
                                                                            }
                                                                            setLibraryArray(newArr)
                                                                        }
                                                                        }
                                                                    >
                                                                        <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                                                        <span>New Item</span>
                                                                    </div> : ""
                                                        }

                                                    </Grid>
                                                    <Grid xs={1} sm={1} md={2} lg={2}></Grid>
                                                    {/* level 2 selection start here */}
                                                    <Grid item xs={4} sm={3} md={2} lg={2}>
                                                        {activeLevel1 == 'level1' + index && librarybrandcheck[index] == 2 && selectedBrand.length > 0 ? selectedBrand.map((tare, tid) => {
                                                            return (
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center"
                                                                >
                                                                    <Grid xs={9}>
                                                                        <FormControl className='formControl'>
                                                                            <Select
                                                                                className={activeidforClasslevel2 == tare.brand_id + 'level2' + index ? "activeclass selectBx" : "selectBx"}
                                                                                value={tare.brand_id ? tare.brand_id : ""}
                                                                                disabled={tare.selected || tare.brand_id ? true : false}
                                                                                onChange={(e) => {
                                                                                    // alert("qq")
                                                                                    let newArr = [...libraryArray];
                                                                                    newArr[index]['brand'][tid] = e.target.value;

                                                                                    let brandIndex = (activeid).toString();

                                                                                    let key = -1;
                                                                                    for (let i = 0; i < newArr[index]['categories'].length; i++) {
                                                                                        if ((activeid).toString() == (newArr[index]['categories'][i]).toString()) {
                                                                                            key = i;
                                                                                        }
                                                                                    }
                                                                                    if (key == -1) {
                                                                                        newArr[index]['categories'].push(brandIndex + "_" + e.target.value);
                                                                                    } else {
                                                                                        newArr[index]['categories'][key] = activeid + '_' + e.target.value
                                                                                    }
                                                                                    setLibraryArray(newArr)
                                                                                    let newBrandArray = [...selectedBrand];
                                                                                    let list = []
                                                                                    for (let j = 0; j < activeBrand.length; j++) {
                                                                                        if (activeBrand[j].brand_id == e.target.value) {
                                                                                            activeBrand[j].selected = true;
                                                                                            newBrandArray[tid] = activeBrand[j]
                                                                                        }
                                                                                    }
                                                                                    setselectedBrand(newBrandArray)
                                                                                }
                                                                                }
                                                                                label="Select Therapeutic Areas"
                                                                                placeholder="Select Therapeutic Areas"
                                                                                disableUnderline='true'>
                                                                                {activeBrand.length > 0 ? activeBrand.map((alltherapeuticArea, alltid) => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            disabled={arr.brand.includes(alltherapeuticArea.brand_id) ? true : false}
                                                                                            key={alltid + "brand2"}
                                                                                            value={alltherapeuticArea.brand_id}
                                                                                        >
                                                                                            {alltherapeuticArea.brand_name}
                                                                                        </MenuItem>
                                                                                    )
                                                                                }) : ""}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <button onClick={(e) => {
                                                                            removeLevel2(index, "b", selectedBrand, tid, tare.brand_id)
                                                                        }
                                                                        } className='iconImg removeIcon' ></button>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <img style={{ paddingTop: '10px', paddingLeft: '5px', marginLeft: '10px' }} onClick={(e) => {
                                                                            setactiveIndex(index)
                                                                            setactiveTid(tid)
                                                                            setactiveCatlevel2(index, tid, tare.brand_id, "b")
                                                                        }
                                                                        } src={require('../../../public/img/Add@3x.png')} className='addImg2' alt='img' />
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        }) : ""}

                                                        {activeLevel1 == 'level1' + index && librarybrandcheck[index] == 3 && selectedIndication.length > 0 ? selectedIndication.map((tare, tid) => {
                                                            return (
                                                                <>
                                                                    <Grid container
                                                                        direction="row"
                                                                        justify="flex-start"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item xs={9}>
                                                                            <FormControl className='formControl'>
                                                                                <Select
                                                                                    className={activeidforClasslevel2 == tare.indication_id + 'level2' + index ? "activeclass selectBx" : "selectBx"}
                                                                                    value={tare.indication_id ? tare.indication_id : " "}
                                                                                    disabled={tare.indication_id ? true : false}
                                                                                    onChange={(e) => {
                                                                                        let newArr = [...libraryArray];
                                                                                        newArr[index]['indication'][tid] = e.target.value;

                                                                                        let brandIndex = (activeid).toString();

                                                                                        let key = -1;
                                                                                        for (let i = 0; i < newArr[index]['categories'].length; i++) {
                                                                                            if (activeid == (newArr[index]['categories'][i]).toString()) {
                                                                                                key = i;
                                                                                            }
                                                                                            if ("_" + activeid == (newArr[index]['categories'][i]).toString()) {
                                                                                                key = i;
                                                                                            }
                                                                                        }
                                                                                        if (librarythraupatiAreacheck[index] == 1) {
                                                                                            if (key == -1) {
                                                                                                newArr[index]['categories'].push(brandIndex + "__" + e.target.value);
                                                                                            } else {
                                                                                                if (librarybrandcheck[index] == 3) {
                                                                                                    newArr[index]['categories'][key] = activeid + '__' + e.target.value
                                                                                                } else {
                                                                                                    newArr[index]['categories'][key] = activeid + '__' + e.target.value
                                                                                                }

                                                                                            }
                                                                                        } else {
                                                                                            if (key == -1) {
                                                                                                newArr[index]['categories'].push('_' + brandIndex + "_" + e.target.value);
                                                                                            } else {
                                                                                                if (librarybrandcheck[index] == 3) {
                                                                                                    newArr[index]['categories'][key] = "_" + activeid + '_' + e.target.value
                                                                                                } else {
                                                                                                    newArr[index]['categories'][key] = "_" + activeid + '_' + e.target.value
                                                                                                }

                                                                                            }
                                                                                        }

                                                                                        setLibraryArray(newArr)
                                                                                        let newBrandArray = [...selectedIndication];
                                                                                        let list = []
                                                                                        for (let j = 0; j < activeIndication.length; j++) {
                                                                                            if (activeIndication[j].indication_id == e.target.value) {
                                                                                                activeIndication[j].selected = true;
                                                                                                newBrandArray[tid] = activeIndication[j]
                                                                                            }
                                                                                        }
                                                                                        setselectedIndication(newBrandArray)
                                                                                    }
                                                                                    }
                                                                                    label="Select Therapeutic Areas"
                                                                                    placeholder="Select Therapeutic Areas"
                                                                                    disableUnderline='true'>
                                                                                    {alllibraryindication.length > 0 ? activeIndication.map((alltherapeuticArea, alltid) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                disabled={arr.indication.includes(alltherapeuticArea.indication_id) ? true : false}
                                                                                                key={alltid + "indication2"}
                                                                                                value={alltherapeuticArea.indication_id}
                                                                                            >
                                                                                                {alltherapeuticArea.indication_name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    }) : ""}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={1}>
                                                                            <button onClick={(e) => {
                                                                                removeLevel2(index, "i", selectedIndication, tid, tare.indication_id)
                                                                            }
                                                                            } className='iconImg removeIcon' ></button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }) : ""}

                                                        {activeLevel1 == 'level1' + index && librarythraupatiAreacheck[index] != undefined && librarybrandcheck[index] == 2 && selectedBrand.length < activeBrand.length && hideshowlevel[index + "_level2"] &&
                                                            <div className='itemAdd'
                                                                onClick={(e) => {
                                                                    let newArr = [...libraryArray];
                                                                    if (librarybrandcheck[index] == 2) {
                                                                        newArr[index]['brand'].push(0);
                                                                        let brandArr = [...selectedBrand];
                                                                        brandArr.push(0);
                                                                        setselectedBrand(brandArr)

                                                                    }
                                                                    else if (librarybrandcheck[index] == 3) {
                                                                        newArr[index]['indication'].push(0);
                                                                        let brandArr = [...selectedIndication];
                                                                        brandArr.push(0);
                                                                        setselectedIndication(brandArr)
                                                                    }
                                                                    setLibraryArray(newArr)
                                                                }
                                                                }>
                                                                <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                                                <span>New Item</span>
                                                            </div>
                                                        }
                                                        {activeLevel1 == 'level1' + index && librarythraupatiAreacheck[index] != undefined && librarybrandcheck[index] == 3 && selectedIndication.length < activeIndication.length && hideshowlevel[index + "_level2"] &&
                                                            <div className='itemAdd'
                                                                onClick={(e) => {
                                                                    let newArr = [...libraryArray];
                                                                    if (librarybrandcheck[index] == 2) {
                                                                        newArr[index]['brand'].push(0);
                                                                        let brandArr = [...selectedBrand];
                                                                        brandArr.push(0);
                                                                        setselectedBrand(brandArr)

                                                                    }
                                                                    else if (librarybrandcheck[index] == 3) {
                                                                        newArr[index]['indication'].push(0);
                                                                        let brandArr = [...selectedIndication];
                                                                        brandArr.push(0);
                                                                        setselectedIndication(brandArr)
                                                                    }
                                                                    setLibraryArray(newArr)
                                                                }
                                                                }>
                                                                <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                                                <span>New Item</span>
                                                            </div>
                                                        }

                                                    </Grid>




                                                    {/*level 3 selection start here  */}
                                                    <Grid xs={1} sm={1} md={2} lg={2}></Grid>
                                                    <Grid item xs={4} sm={3} md={2} lg={2}>
                                                        {activeLevel2 == 'level2' + index && libraryindicationcheck[index] == 3 && selectedIndicationLevel3.length > 0 ? selectedIndicationLevel3.map((tare, tid) => {
                                                            return (
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center"
                                                                >
                                                                    <Grid xs={9}>
                                                                        <FormControl className='formControl'>
                                                                            <Select
                                                                                className='selectBx'
                                                                                value={tare != 0 && tare.indication_id ? tare.indication_id : ""}
                                                                                disabled={tare.indication_id ? true : false}
                                                                                onChange={(e) => {
                                                                                    let newArr = [...libraryArray];
                                                                                    newArr[index]['indication'][tid] = e.target.value;

                                                                                    let thIndex = (activeid).toString();
                                                                                    let brandIndex = (activebrandid).toString();

                                                                                    let key = -1;
                                                                                    for (let i = 0; i < newArr[index]['categories'].length; i++) {
                                                                                        if (thIndex + "_" + (brandIndex).toString() == (newArr[index]['categories'][i]).toString()) {
                                                                                            key = i;
                                                                                        }
                                                                                    }
                                                                                    if (key == -1) {
                                                                                        newArr[index]['categories'].push(thIndex + "_" + (brandIndex).toString() + "_" + e.target.value);
                                                                                    } else {
                                                                                        newArr[index]['categories'][key] = thIndex + "_" + (brandIndex).toString() + '_' + e.target.value
                                                                                    }
                                                                                    setLibraryArray(newArr)
                                                                                    let newBrandArray = [...selectedIndicationLevel3];
                                                                                    let list = []
                                                                                    for (let j = 0; j < activeIndicationLevel3.length; j++) {
                                                                                        if (activeIndicationLevel3[j].indication_id == e.target.value) {
                                                                                            activeIndicationLevel3[j].selected = true;
                                                                                            newBrandArray[tid] = activeIndicationLevel3[j]
                                                                                        }
                                                                                    }
                                                                                    setselectedIndicationLevel3(newBrandArray)
                                                                                }
                                                                                }
                                                                                label="Select Therapeutic Areas"
                                                                                placeholder="Select Therapeutic Areas"
                                                                                disableUnderline='true'>
                                                                                {activeIndicationLevel3.length > 0 ? activeIndicationLevel3.map((alltherapeuticArea, alltid) => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            key={alltid}
                                                                                            disabled={arr.indication.includes(alltherapeuticArea.indication_id) ? true : false}
                                                                                            value={alltherapeuticArea.indication_id}
                                                                                        >
                                                                                            {alltherapeuticArea.indication_name}
                                                                                        </MenuItem>
                                                                                    )
                                                                                }) : ""}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <button onClick={(e) => {
                                                                            removeLevel3(index, selectedIndicationLevel3, tid, tare.indication_id)
                                                                        }
                                                                        }
                                                                            className='iconImg removeIcon' ></button>
                                                                    </Grid>
                                                                </Grid>

                                                            )
                                                        }) : ""}
                                                        {activeLevel2 == 'level2' + index && libraryindicationcheck[index] != undefined && selectedIndicationLevel3.length < activeIndicationLevel3.length &&
                                                            <div className='itemAdd' onClick={(e) => {
                                                                let newArr = [...libraryArray];
                                                                newArr[index]['indication'].push(0);
                                                                let brandArr = [...selectedIndicationLevel3];
                                                                brandArr.push(0);
                                                                setselectedIndicationLevel3(brandArr)
                                                                setLibraryArray(newArr)
                                                            }
                                                            } >
                                                                <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                                                <span>New Item</span>
                                                            </div>
                                                        }
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid container
                                                xs={12}
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                            >

                                                <Grid item xs={12}>
                                                    <label>Edit fixed menu</label>
                                                </Grid>
                                                {arr['fixedmenu'].length <= 3 ?
                                                    <Grid onClick={(e) => {
                                                        let newArr = [...libraryArray];
                                                        newArr[index]['fixedmenu'].push('');

                                                        setLibraryArray(newArr)
                                                    }
                                                    } className='itemAdd' >
                                                        <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                                        <span>New Item</span>
                                                    </Grid>
                                                    : ""}
                                                {arr.fixedmenu.map((fixedMenu, ind) => {
                                                    return (
                                                        <>
                                                            <Grid container key={index}>
                                                                <Grid item xs={9} sm={3} md={3} lg={3}>
                                                                    <FormControl className='formControl'>
                                                                        <Select value={fixedMenu} className='selectBx' onClick={(e) => {
                                                                            let newArr = [...libraryArray];
                                                                            newArr[index]['fixedmenu'][ind] = e.target.value;

                                                                            setLibraryArray(newArr)
                                                                        }
                                                                        } label="Select Therapeutic Areas" placeholder="Select Therapeutic Areas" disableUnderline='true'>
                                                                            {props.therapeuticAreas.length > 0 ? props.therapeuticAreas.map((therapeuticArea, i) => {
                                                                                let disabledBool = arr['fixedmenu'].includes(therapeuticArea.therapeutic_area_id) ? true : false;
                                                                                return (
                                                                                    <MenuItem disabled={disabledBool} key={i} value={therapeuticArea.therapeutic_area_id}>{therapeuticArea.therapeutic_area_name}</MenuItem>
                                                                                )
                                                                            }) : ""}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={3} lg={2}>
                                                                    <button className='iconImg removeIcon' onClick={(e) => {
                                                                        let newArr = [...libraryArray];
                                                                        let arrayFix = newArr[index]['fixedmenu'].filter((item) => { return item !== fixedMenu; });
                                                                        newArr[index]['fixedmenu'] = arrayFix

                                                                        setLibraryArray(newArr)
                                                                    }}></button>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )
                                                })}
                                            </Grid>
                                        </TabPanel>
                                    </>
                                ))}

                            </TabContext>
                        </>
                        : null}
                </Grid>
            </Grid>
        </>
    )
}

export default Library