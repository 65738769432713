/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PlaylistService } from '../Service/playlist-service';

const ContentAffiliates = (props) => {

  const [countryStatus, setCountryStatus] = React.useState(false);
  const [hubs, SetHubs] = React.useState([]);
  const [hubCheckbox, SetHubCheckbox] = React.useState({});
  const [hubAffiliateDict, SethubAffiliateDict] = React.useState({});
  const [selectedAffiliates, SetSelectedAffiliates] = React.useState([]);
  const [Status, checkStatus] = React.useState(false);
  const [StatcountryStateus, setCountryState] = React.useState([]);
  
  const AlterAffiliateState = (event, affiliate, affiliateObj, hub) => {
    if (props.id != '0' && !props.changeCountryState) {
      let newarr = [];
      newarr.push({ event: event.target.checked+"country", affiliate: affiliate, affiliateObj: affiliateObj, hub: hub, type: 'singleCountry' });
      setCountryState(newarr)
      props.setdetectChange(true)
      return
    }
    checkStatus(true);
    props.SetCheckAffiliates(true);
    let presentAffiliate = selectedAffiliates;
    let checkUncehckTest = true;
    if (presentAffiliate.includes(affiliate)) {
      checkUncehckTest = false;
    }
    let list = [];
    for (let i = 0; i < presentAffiliate.length; i++) {
      if (presentAffiliate[i] != null) {
        if (affiliate !== presentAffiliate[i]) {
          list.push(presentAffiliate[i]);
        }
      }
    }
    if (event == "truecountry" || (event.target && event.target.checked)) {
      props.addAffiliates(affiliateObj);
      props.SetAffiliates([...props.affiliates, affiliateObj.country_code]);
      props.SetAffiliatesName([...props.affiliatesName, affiliateObj])


    } else {
      props.removeAffiliates(affiliateObj);
      props.SetAffiliates(props.affiliates.filter(item => item !== affiliateObj.country_code));

      props.SetAffiliatesName(props.affiliatesName.filter(item => item.country_code !== affiliateObj.country_code))

    }
    if (checkUncehckTest) {
      list.push(affiliate);
    }
    SetSelectedAffiliates(list);

    let listofAffiliates = hubAffiliateDict[hub.hub_id];
    let selectedAffiliateIds = Object.keys(listofAffiliates);
    let checkList = [];
    for (let i = 0; i < (selectedAffiliateIds).length; i++) {
      if (list.includes(parseInt(selectedAffiliateIds[i]))) {
        checkList.push(true);
      } else {
        checkList.push(false);
      }
    }
    if (!checkList.includes(false)) {
      let tempHubCheckBox = hubCheckbox;
      tempHubCheckBox[hub.hub_id] = true;
      SetHubCheckbox(tempHubCheckBox);
    } else {
      let tempHubCheckBox = hubCheckbox;
      tempHubCheckBox[hub.hub_id] = false;
      SetHubCheckbox(tempHubCheckBox);
    }
    // props.setchangeCountryState(false)
  }

  // To Get All Affiliates Countries
  const GetAllAffiliatesFunc = (hubs, selectedAffiliates) => {
    let service = new PlaylistService();
    service.GetAllAffiliates().then((data) => {
      let affiliates = data.data;
      if (affiliates.length > 0) {
        if (hubs.length > 0) {
          let hubDict = {}
          let hubCheckbox = {}
          for (let k = 0; k < hubs.length; k++) {
            let affiliateDict = {};
            let checkList = [];
            for (let i = 0; i < affiliates.length; i++) {
              if (hubs[k].affiliate_code.includes(affiliates[i].country_code)) {
                affiliateDict[affiliates[i].country_code] = affiliates[i];
                if (typeof selectedAffiliates != 'undefined') {
                  if (selectedAffiliates.includes(affiliates[i].country_code)) {
                    checkList.push(true);
                  } else {
                    checkList.push(false);
                  }
                } else {
                  checkList.push(false);
                }
              }
            }
            if (checkList.includes(false)) {
              hubCheckbox[hubs[k].hub_id] = false;
            } else {
              hubCheckbox[hubs[k].hub_id] = true;
            }
            hubDict[hubs[k].hub_id] = affiliateDict;
          }
          SetHubCheckbox(hubCheckbox);
          SethubAffiliateDict(hubDict);
          setCountryStatus(true);
          let countryObject = [];
          for (let i = 0; i < affiliates.length; i++) {
            if ((props.affiliates).includes(affiliates[i].country_code)) {
              countryObject.push(affiliates[i])
            }
          }

          props.SetAffiliatesName(countryObject);
        }
      }
    }).catch((err) => {});
  }

  // To Get All Hub Countries
  const GetAllHubsFunc = (affiliateIds) => {
    let service = new PlaylistService();
    service.GetAllHubs().then((data) => {
      SetHubs(data.data);
      GetAllAffiliatesFunc(data.data, affiliateIds);
    }).catch((err) => {});
  }

  React.useEffect(() => {
    if (!Status) {
      GetAllHubsFunc();
    }

  }, [props.affiliates, Status])
  const CheckHubs = (hubId) => {
    let affiliates = Object.keys(hubAffiliateDict[hubId]);
    for (let i = 0; i < affiliates.length; i++) {
      if (!props.affiliates.includes(affiliates[i])) {
        return false;
      }
    }
    return true;
  }
  const FillAffiliatesIntoSelectedAffiliates = (affiliates, selectedAffiliatessArray) => {
    for (let i = 0; i < affiliates.length; i++) {
      let affiliate = affiliates[i];
      if (!selectedAffiliatessArray.includes(affiliate)) {
        selectedAffiliatessArray.push(affiliate);
      }
    }
    return selectedAffiliatessArray;
  }

  const AlterParentAffiliateState = (ids, hubid, state, event) => {
    // alert("219",props.changeCountryState)
    if (props.id != '0' && !props.changeCountryState) {
      let newarr = [];
      newarr.push({ ids: ids, hubid: hubid, state: state, event: event.target.checked, type: 'hub' });
      setCountryState(newarr)
      props.setdetectChange(true)
      return
    }
    let affiliates = Object.keys(hubAffiliateDict[hubid]);
    let presentList = Object.assign([], props.affiliates);
    if (!event || (event.target && !event.target.checked)) {
      let newList = presentList.filter((v) => !affiliates.includes(v));
      SetSelectedAffiliates(newList);
      let arrary = []
      for (let i=0;i<newList.length;i++) {
        for (let j = 0; j < props.affiliatesName.length; j++) {
          if (props.affiliatesName[j].country_code === newList[i]) {
            arrary.push(props.affiliatesName[j]);
          }
        }
      }
      props.SetAffiliatesName(arrary);
      props.SetAffiliates(newList);
    }
    else {
      for (let i = 0; i < affiliates.length; i++) {
        if (!presentList.includes(affiliates[i])) {
          presentList.push(affiliates[i]);
        }
      }
      for (let i = 0; i < props.affiliates.length; i++) {
        if (!presentList.includes(props.affiliates[i])) {
          presentList.push(props.affiliates[i]);
        }
      }
      let arrary = []
      for (const key in ids) {
        if (!arrary.includes(ids[key])) {
          arrary.push(ids[key])
        }
      }
      for (const key in props.affiliatesName) {
        if (!arrary.includes(props.affiliatesName[key])) {
          arrary.push(props.affiliatesName[key])
        }
      }
      SetSelectedAffiliates(presentList);
      props.SetAffiliatesName(arrary)
      props.SetAffiliates(presentList)
    }
  }


  React.useEffect(() => {
    // alert("227",props.changeCountryState)
    if(props.changeCountryState){
      // alert("280",props.changeCountryState)
      if(StatcountryStateus[0]['type'] == 'hub'){
        AlterParentAffiliateState(StatcountryStateus[0]['ids'], StatcountryStateus[0]['hubid'], StatcountryStateus[0]['state'], StatcountryStateus[0]['event'])
      }else{
        AlterAffiliateState (StatcountryStateus[0].event, StatcountryStateus[0].affiliate, StatcountryStateus[0].affiliateObj, StatcountryStateus[0].hub)
      }
      // props.setchangeCountryState(false)
    }
    
  }, [props.changeCountryState])
  return (
    <Grid container className="content white_row">
      <Grid container className="content_inner_box">
        <Grid item lg={12} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Country Availability (If a country is selected, this video will be displayed externally upon publisher's approval)</FormLabel>
            {countryStatus ?
              <ul className='country_list'>
                {
                  hubs.length > 0 ? hubs.map((hub, index) => {
                    return (
                      <>
                        <Grid className='hubs' key={'1' + index}>
                          <li className='country_list_inner'>
                            <FormControlLabel
                              value={hub.hub_name}
                              className='labelTxt-user'
                              checked={CheckHubs(hub.hub_id)}
                              control={<Checkbox onChange={(event) => { AlterParentAffiliateState(hubAffiliateDict[hub.hub_id], hub.hub_id, hubCheckbox[hub.hub_id], event); }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                              label={hub.hub_name}
                              labelPlacement="end"
                            />
                          </li>
                        </Grid>
                        <Grid className='affiliates' key={'2' + index}>
                          {
                            Object.values(hubAffiliateDict[hub.hub_id]).length > 0 ? Object.values(hubAffiliateDict[hub.hub_id]).map((affiliate, innerIndex) => {
                              return (
                                <li className='country_list_inner' key={innerIndex}>
                                  <FormControlLabel
                                    value={affiliate.country_name}
                                    className='labelTxt-user'
                                    checked={props.affiliates.includes(affiliate.country_code)}
                                    control={<Checkbox onChange={(event) => { AlterAffiliateState(event, affiliate.country_code, affiliate, hub); props.setIsAffiliateAltered(true);}}
                                      inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                    label={affiliate.country_name}
                                    labelPlacement="end"
                                  />
                                </li>
                              )
                            }) : ('')
                          }
                        </Grid>
                      </>
                    )
                  }) : ('')
                }
              </ul>
              : "Loading..."}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ContentAffiliates