/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import { useLocation } from 'react-router-dom';
import { SessionService } from '../modules/login/Services/SessionService';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { VideoPlayerService } from '../modules/video-player/Service/video-player';
import { AppFooterService } from './Services/AppFooter';
import countryListJson from '../CountryPassport/CountryPassport.json';
import { setAffiliate } from '../Session/AffiliateSlice';
const AppMain = (props) => {
  function getCountryDetails(country_name) {
    if(country_name !== undefined) {
        if(countryListJson.length) {
            for (var i = 0; i < countryListJson.length; i++){
                let cntName = countryListJson[i].country.split(" ").join("").toLowerCase();
                if (cntName == country_name){
                    return countryListJson[i];
                }
            }
        }
    }
    return '';
  }

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let affiliateSelector = useSelector(state=>state.affilaiteSlice);
  let categoriesSelector = useSelector(state=> state.categorySlice);
  const videoPlayerService = new VideoPlayerService();
  let selectorValue = useSelector(state=> state.session);
  const translationReduxState = useSelector(state => state.translation);
  const dimension = useSelector(state=>state.playerDimesion)
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [isFooter, SetisFooter] = React.useState(false);
  const [role, setRole] = React.useState([]);
  const [previousRoute, SetPreviousRoute] = React.useState('');
  const [isHeaderFooter, SetIsHeaderFooter] = React.useState(true);
  const [rootClass, SetrootClass] = React.useState('container-height');
  React.useEffect(() => {
    SetSessionState(selectorValue);
  }, [selectorValue]);
  React.useEffect(()=> {
    if(previousRoute.includes('content-watch')){
      if(dimension){
        if(dimension.dimension){
          if(dimension.dimension.payload){
            // videoPlayerService.ResettingPosition(dimension.dimension.payload)
          }
        }
      }
    }
    SetPreviousRoute(location.pathname);

    const paths = location.pathname.split("/").filter(entry => entry !== "");
    const country_name = paths[paths.length - 1];
    let countryData = getCountryDetails(country_name);
    if(countryData) {
      SetIsHeaderFooter(false);
      SetrootClass("container-noheight");
    }
    if(location.pathname.includes("/landing/")) {
      SetIsHeaderFooter(false);
      SetrootClass("container-noheight");
    }
    if(location.pathname === '/') {
      SetIsHeaderFooter(false);
      SetrootClass("container-noheight");
    }
    if(location.pathname === '/sso') {
      SetIsHeaderFooter(false);
      SetrootClass("container-noheight");
    }
  },[location]);
  React.useEffect(() => {
    let isHCPRole = false;
    sessionState?.sessionValue?.payload?.roles?.forEach((role) => {
      if (role.role_id == 1) {
        isHCPRole = true;
      }
    });
    if (sessionState.sessionValue?.payload?.loginroute) {
      SetIsHeaderFooter( true);
      SetrootClass("container-height");
      if (isHCPRole) {
        let param = localStorage.getItem('country');
        if (param) {
          let footerService = new AppFooterService();
          footerService.GetAffiliateByQuery({ affiliate_id: param ,   _dispatch: dispatch, _categoriesSelector:  categoriesSelector,_affiliateSelector : affiliateSelector, _setAffiliate:setAffiliate}).then((data) => {
            let countries = data.data;
            if (countries.length !== 0) {
              SetisFooter(countries[0].footer ?? false);
            }
          }).catch((err) =>{})
        }
      }
        
      let userRoles = [];
      for (let i = 0; i < sessionState.sessionValue.payload.roles.length; i++) {
        if (sessionState.sessionValue.payload.roles[i].role_name === "hcp") {
          userRoles.push("hcp");
          props.setIsRoleHCP(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "internal") {
          userRoles.push("internal");
          props.setIsRoleInternal(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "publisher") {
          userRoles.push("publisher");
          props.setIsRolePublisher(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "admin") {
          userRoles.push("admin");
          props.setIsRoleAdmin(true);
        }
      }
      props.SetAreRolesSet(true);
      setRole(userRoles);
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState]);

  React.useEffect(()=> {
    let indicator = localStorage.getItem('HCP');
    let model = new SessionService(localStorage.getItem('jwtToken'));
    if(localStorage.getItem('sessionValue')){
      const sessionDate = localStorage.getItem('sessionDate');
      if (sessionDate) {
        try{
          let cookie = model.GetSessionValueFromHash(navigate, dispatch, props.setmainTranslation);
        }
        catch(err){
          let previousRoute = localStorage.getItem('previousRouteBeforeLogin')
          window.location.href = window.location.origin + '/' + previousRoute;
        }
      } else {
        let redirect = localStorage.getItem('country')?localStorage.getItem('country').toLowerCase():'';
        localStorage.clear();
        if (indicator === 'true') {
            sessionStorage.clear();
            window.location.href = window.location.origin + '/sso';
        } else {
            window.location.href = window.location.origin + '/' + redirect;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div>
      <>
        {isHeaderFooter ? <AppHeader Setallcategory={props.Setallcategory} allcategory={props.allcategory} SetallThArea= {props.SetallThArea} Setplay={props.Setplay}  SetPlayerShow={props.SetPlayerShow} SetselectedLibraryid={props.SetselectedLibraryid} selectedLibraryid={props.selectedLibraryid} SetselectedCountry={props.SetselectedCountry} selectedCountry={props.selectedCountry} selectedLibrary={props.selectedLibrary} SetselectedLibrary={props.SetselectedLibrary} libraryData={props.libraryData} SetlibraryData={props.SetlibraryData} openLibraryModal={props.openLibraryModal} SetopenLibraryModal={props.SetopenLibraryModal} SetFilterId={props.SetFilterId} filterId={props.filterId} updateFilter={props.handleFilterChange} translations={props.translations} setFilter={props.setFilter} filter={props.filter} setSearch={props.setSearch} search={props.search} country={props.country} setmainTranslation={props.setmainTranslation} selectorValueSession={selectorValue}/> : null}
        <div className={rootClass}>
          {props.children}
        </div>
        {isFooter ? isHeaderFooter ? <AppFooter /> : null : null}
      </>
    </div>
  )
};

AppMain.propTypes = {
  children: PropTypes.any,
};

export default AppMain;
