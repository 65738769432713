/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumb from '../../../../layout/breadcrumb';
import Modal from '@material-ui/core/Modal';
import CreateSpeaker from '../speaker-create/speaker-create';
import { SpeakerService } from '../Services/speaker-service';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import '../speaker.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SpeakerThumbnail from '../Components/SpeakerThumbnail'
// Define Prototype
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Define Data Table Header
const headCells = [
  { id: 'speaker', numeric: false, disablePadding: false, label: 'Speaker', sorting: false },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sorting: true },
  { id: 'related_videos', numeric: false, disablePadding: false, label: 'Related videos', sorting: true },
  { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false },
];

// To Create Data Table Rows
function createData(speaker_id, speaker, name, related_videos, action, updated_on, avatar_attachment_id) {
  return { speaker_id, speaker, name, related_videos, action, updated_on, avatar_attachment_id };
}

// Functions for Apply Sorting of Data Table
/**Decending comparator on header */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}
/**Fetches comparator of order on header */
function getComparator(order, orderBy) {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**Sort function on header */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sorting}
              class={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='visuallyHidden'>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const useStyles = makeStyles((theme) => ({
}));

// Speaker Management Component 
export default function EnhancedTable(props) {
  let speakerService = new SpeakerService();
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [createSpeakerPopup, setCreateSpeakerPopup] = React.useState(false);
  const [rows, SetRows] = React.useState([]);
  const [speakerData, setSpeakerData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [fetchDataStatus, setFetchDataStatus] = React.useState(false);
  const [loaderBool, setLoaderBool] = React.useState(false);
  const [searchOnOff, setsearchOnOff] = React.useState(false);


  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Speaker Management', path: '/speaker-management' }
  ];
  const handleRequestSort = (event, property) => {
    if (property == 'related_videos' || property == 'name') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }

  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if(!searchTerm){

    getSetSpeakers({ searchKeyword: searchTerm }, offSet, limit);
    }

  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // To Get All Contents Data
  function getAllSpeakers() {
    speakerService.GetAllSpeakerByQuery().then((data) => {
      setallRow(data.data)
      settotalLength(data.data.length)
    }).catch((err) => {  setLoaderBool(false) })

  }
  function getSetSpeakersSearch(params = {}) {
    setLoaderBool(true)
    setFetchDataStatus(false)
    speakerService.GetAllSpeakerByQuery().then((data) => {
      const filteredSpeakerList = data.data.filter(speaker => speaker.name.trim().toLowerCase().includes(params.searchKeyword.trim().toLowerCase()))
      let speakers = filteredSpeakerList
      let list = [];
      speakerService.GetAllContent().then((content) => {
        // let speakers = data.data;
        let videos = content.data;
        if (speakers.length > 0) {
          for (let i = speakers.length - 1; i >= 0; i--) {


            speakers[i].videocount = 0;
            for (let j = 0; j < videos.length; j++) {
              if (videos[j].speakers_id) {
                if (videos[j].speakers_id.includes(speakers[i].speaker_id)) {
                  speakers[i].videocount = parseInt(speakers[i].videocount) + 1
                }
              }
            }
            let speaker = speakers[i];
            let value = createData(speaker.speaker_id, speaker.avatar_attachment_id,
              capitalizeFirstLetter((speaker.name).trim()), speaker.videocount, '', new Date(speaker.updated_at),
              speaker.avatar_attachment_id);
            list.push(value);
          }
          list.sort((a, b) => ((b.updated_on ? new Date(b.updated_on) : new Date(8640000000000000)) - (a.updated_on ? new Date(a.updated_on) : new Date(8640000000000000))));
          let newarr = []
          newarr = newarr.concat(list);
          SetRows(newarr)
          setSpeakerData(newarr);
          setoffSet(0)
        }


        if (speakers.length === 0) {
          SetRows(list);
          setSpeakerData(data.data);
        }
        setLoaderBool(false)
      })
    }).catch((err) => {  setLoaderBool(false) })
  }
  function getSetSpeakers(params = {}, start, end) {
    setLoaderBool(true)
    speakerService.GetSpeakerByQuery(params, start, end).then((data) => {
      let list = [];
      speakerService.GetAllContent().then((content) => {
        let speakers = data.data;
        let videos = content.data;
        if (speakers.length > 0) {
          for (let i = speakers.length - 1; i >= 0; i--) {
            speakers[i].videocount = 0;
            for (let j = 0; j < videos.length; j++) {
              if (videos[j].speakers_id) {
                if (videos[j].speakers_id.includes(speakers[i].speaker_id)) {
                  speakers[i].videocount = parseInt(speakers[i].videocount) + 1
                }
              }
            }
            let speaker = speakers[i];
            let value = createData(speaker.speaker_id, speaker.avatar_attachment_id,
              capitalizeFirstLetter((speaker.name).trim()), speaker.videocount, '', new Date(speaker.updated_at),
              speaker.avatar_attachment_id);
            list.push(value);
          }
          list.sort((a, b) => ((b.updated_on ? new Date(b.updated_on) : new Date(8640000000000000)) - (a.updated_on ? new Date(a.updated_on) : new Date(8640000000000000))));
          if (searchOnOff) {
            let newarr = []
            newarr = newarr.concat(list);
            SetRows(newarr)
            setSpeakerData(newarr);
            setoffSet(offSet + 50)
            setsearchOnOff(false)
          } else {
            let newarr = start == 0 ? [] : rows;
            newarr = newarr.concat(list);
            SetRows([])
            SetRows(newarr)
            setSpeakerData(newarr);
            setoffSet(offSet + 50)
          }
        }
        setFetchDataStatus(true)
        if (speakers.length === 0) {
          SetRows(list);
          setSpeakerData(data.data);
        }
        setLoaderBool(false)
      })
    }).catch((err) => { setLoaderBool(false) })
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setLoaderBool(true)
    // SetRows([])

    getSetSpeakers({}, 0, 50);
    getAllSpeakers();

  }, []);

  const handleReloadBtn = () => {
    // SetRows([])
    setPage(0)
    getAllSpeakers();

    getSetSpeakers({}, 0, 50);
  };

  const [open, setOpen] = React.useState(false);
  const [filteredSpeakers, setFilteredSpeakers] = React.useState([]);
  const [newSpeakerTerm, setNewSpeakerTerm] = React.useState('');
  const [textInput, setTextInput] = React.useState('');
  const [setBlueColor, setFilteredBluecolor] = React.useState(false);
  const [setRedColor, setFilteredRedcolor] = React.useState(false);
  const [activeId, setActiveId] = React.useState('');

  const [limit, setLimit] = React.useState(50);
  const [offSet, setoffSet] = React.useState(0);
  const [allRow, setallRow] = React.useState([]);
  const [totalLength, settotalLength] = React.useState('');

  const handleTextInputChange = event => {
    setTextInput(event.target.value);
  };
  const searchItem = event => {
    let count = 0
    for (let i = 0; i < allRow.length; i++) {
      let name = (allRow[i].name).toLowerCase()
      if (name.includes(searchTerm.toLowerCase())) {
        count = count + 1
      }
    }
    settotalLength(count)
    SetRows([])
    getSetSpeakersSearch({ searchKeyword: searchTerm });
    setPage(0);
    setsearchOnOff(true)

  };
  const handleSearchTerm = (e, value) => {
    if (e.keyCode == 13) {
      let term = e.target.value;
      if (term.length > 0) {
        if (value != null) {
          term = value.user_name;
        }
        setSearchTerm(term);
        let count = 0
        for (let i = 0; i < allRow.length; i++) {
          let name = (allRow[i].name).toLowerCase()
          if (name.includes(term.toLowerCase())) {
            count = count + 1
          }
        }
        settotalLength(count)
        SetRows([])
        getSetSpeakersSearch({ searchKeyword: term });
        setPage(0);
        setsearchOnOff(true)
      } else {
        SetRows([])
        settotalLength(allRow.length)
        getSetSpeakers({}, 0, 50);

      }
    }
  }


  const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
  const [rowID, setrowID] = React.useState(0);
  const [rowName, setrowName] = React.useState('');
  const [validationPopup, setvalidationPopup] = React.useState(false);
  const [validationName, setvalidationName] = React.useState('Speaker Name');

  const handleDelete = () => {
    speakerService.DeleteSpeaker(rowID).then((data) => {
      setopenDelConfirmModal(false);
      getAllSpeakers();

      getSetSpeakers({}, 0, 50);
    }).catch((err) => {});
  }
  const openDelConfirm = (id, name) => {
    setrowID(id);
    setrowName(name);
    setopenDelConfirmModal(true);
  };
  const handleDelConfirmClose = () => {
    setopenDelConfirmModal(false);
  };
  const openCreatePopup = (id) => {
    setrowID(id);
    setCreateSpeakerPopup(true)
  };
  const handleValidationPopup = () => {
    setvalidationPopup(false);
  }

  return (
    <>

      <Modal open={validationPopup} onClose={handleValidationPopup}>
        <Grid className='paper modalstyle validationPopup'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handleValidationPopup()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="warning">{validationName}: {validationName} cannot be left empty.</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer ">
            <button type="button" onClick={() => handleValidationPopup(false)} className="modalBtn validationpopupFooter">Close</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={createSpeakerPopup}>
        <CreateSpeaker validation={setvalidationPopup} speakerID={rowID} setValiadationName={setvalidationName} closePopUp={setCreateSpeakerPopup} reloadData={handleReloadBtn} />
      </Modal>
      <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="warning">Speaker <b>{rowName}</b> will be deleted, are you sure?</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer">
            <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
            <button type="button" onClick={() => handleDelete()} className="modalBtn">OK</button>
          </Grid>
        </Grid>
      </Modal>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <div className='content-mgmt'>
        <div className="main-wrp">
          <Grid className="mgmt_header">
            <Grid container className="row">
              <Grid item lg={7} sm={6} xs={12}>
                <label className="label-hd">
                  Speaker Management <span className='user'> {totalLength} speakers</span>
                </label>
              </Grid>
              <Grid item lg={3} sm={3} xs={12}>
                <div className='search_bar searchBx'>
                  <Autocomplete
                    open={open}
                    onOpen={() => { if (searchTerm) { setOpen(true); } }}
                    onClose={() => setOpen(false)}
                    inputValue={searchTerm}
                    onInputChange={(e, value) => {
                      setSearchTerm(value);
                      if (value == null) {
                        setFilteredSpeakers([]);
                      } else {
                        let newSpeakersData = speakerData.filter(function (item) {
                          if (item.name != null) {
                            let username = item.name.toLowerCase();
                            return username.startsWith(value.toLowerCase());
                          }
                        });
                        setFilteredSpeakers(newSpeakersData);
                        const found = speakerData.some(el => { if (el.name != null) { return el.name.toLowerCase() === value.toLowerCase() } });
                        if (!found) { setNewSpeakerTerm(value); } else { setNewSpeakerTerm(''); }
                      }
                      if (!value) { setOpen(false); }
                    }}
                    value={searchTerm}
                    onChange={handleSearchTerm}
                    onKeyDown={handleSearchTerm}
                    freeSolo
                    options={filteredSpeakers.length > 0 || newSpeakerTerm != '' ? filteredSpeakers : speakerData}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                    renderInput={(params) =>
                      <TextField {...params} placeholder="Search by name" onInput={handleTextInputChange} name="search_name" size="small"
                        InputLabelProps={{ shrink: false }}
                        {...params.InputProps.disableUnderline = true}
                        {...params.InputProps.endAdornment = <InputAdornment onClick={() => searchItem()} position="end"><IconButton className="mp0" ><SearchIcon /></IconButton></InputAdornment>}
                      />}
                  />
                </div>
              </Grid>
              <Grid item lg={2} sm={3} xs={12}>
                <div className="action_buttons text-right">
                  <Button className='createBtn' onClick={() => openCreatePopup('')}>Create New Speaker</Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table className='table'>
              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.speaker_id}
                        selected={isItemSelected}
                      >
                        <TableCell align="left"><SpeakerThumbnail speaker={row} /></TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.related_videos}</TableCell>
                        <TableCell align="center">
                          {setBlueColor && activeId == row.speaker_id ? (
                            <img onMouseLeave={() => { setFilteredBluecolor(false); setActiveId('') }} onClick={() => openCreatePopup(row.speaker_id)} className='actnBtn' src={require('../../../../public/img/BlueEdit@3x.png')} alt='Edit' />
                          ) : (

                            <img onMouseEnter={() => { setFilteredBluecolor(true); setActiveId(row.speaker_id) }} onClick={() => openCreatePopup(row.speaker_id)} className='actnBtn' src={require('../../../../public/img/GreyEdit@3x.png')} alt='Edit' />
                          )}

                          {setRedColor && activeId == row.speaker_id ? (
                            <img onMouseLeave={() => { setFilteredRedcolor(false); setActiveId('') }} onClick={() => openDelConfirm(row.speaker_id, row.name)} className='actnBtnDel' src={require('../../../../public/img/RedClose@3x.png')} alt='Delete' />
                          ) : (
                            <img onMouseEnter={() => { setFilteredRedcolor(true); setActiveId(row.speaker_id) }} onClick={() => openDelConfirm(row.speakerid, row.name)} className='actnBtnDel' src={require('../../../../public/img/CloseGrey@3x.png')} alt='Delete' />
                          )}


                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    {rows.length <= 0 ?
                      <TableCell align="center" colSpan={4}>{fetchDataStatus ? 'No Records Found.' : 'Loading...'}</TableCell> :
                      <TableCell colSpan={4} />
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  )
}
