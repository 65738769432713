import React from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Multiselect from './Multiselect';
import CustomAccordain from './Accordion';
import { ContentService } from '../Service/content-service';

const GetAllCategoryTree = (therapeuticAreas, category1, category2) => {
    let mainObj = [];
    if (therapeuticAreas.length > 0) {
        for (let t = 0; t < therapeuticAreas.length; t++) {
            let obj = { id: "", name: "", children: [] };
            let ta_id = therapeuticAreas[t].therapeutic_area_id;
            let ta_name = therapeuticAreas[t].therapeutic_area_name;
            obj.id = ta_id;
            obj.name = ta_name;
            obj.children = [];
            if (category1.length > 0) {
                for (let b = 0; b < category1.length; b++) {
                    let cat1_ta_id = category1[b].therapeutic_area_id;
                    let cat1_id = category1[b].category_1_id;
                    let cat1_name = category1[b].category_1_name;
                    if (cat1_ta_id === ta_id) {
                        obj.children.push({ id: cat1_id, name: cat1_name, children: [] });
                    }
                    if (category2.length > 0) {
                        for (let i = 0; i < category2.length; i++) {
                            let cat2_ta_id = cat1_ta_id;
                            let cat2_cat1_id = category2[i].category_1_id;
                            let cat2_id = category2[i].category_2_id;
                            let cat2_name = category2[i].category_2_name;
                            if (cat2_ta_id === ta_id && cat2_cat1_id === cat1_id) {
                                if (obj.children.length > 0) {
                                    for (let k = 0; k < obj.children.length; k++) {
                                        if (obj.children[k] && obj.children[k].id === cat2_cat1_id &&
                                            cat2_ta_id === ta_id && cat2_cat1_id === cat1_id) {
                                            obj.children[k].children.push({ id: cat2_id, name: cat2_name });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            mainObj.push(obj);
        }
    }
    return mainObj;
}
const ContentCategory = (props) => {
    const [categories, SetCategoryTree] = React.useState([]);
    const [checkedItems, setCheckedItems] = React.useState([]);
    const [validateCheckedItems, setValidateCheckedItems] = React.useState(false);
    const [allLibrary, SetallLibrary] = React.useState([]);
    const [selectedlibrary, Setselectedlibrary] = React.useState([]);
    const [allBrandIndications, SetAllBrandIndications] = React.useState([]);
    const [brands, SetBrands] = React.useState([]);
    const [selectedBrands, setSelectedBrands] = React.useState([]);
    const [indications, SetIndications] = React.useState([]);
    const [selectedIndications, setSelectedIndications] = React.useState([]);
    const [flagBrandIndication, setflagBrandIndication] = React.useState(false)

    React.useEffect(() => {
        let country_code = []
        for (let i = 0; i < props.affiliates.length; i++) {
            country_code.push(props.affiliates[i].country_code)
        }
        let tharea = [];
        let cat1 = [];
        let cat2 = [];

        for (let i = 0; i < props.affiliates.length; i++) {
            tharea.push(props.affiliates[i].therapeutic_area_ids)
            cat1.push(props.affiliates[i].category_1_ids)
            cat2.push(props.affiliates[i].category_2_ids)
        }
        let thUnique = []
        let cat1Unique = []
        let cat2Unique = []

        if (tharea.length > 0) {
            thUnique = tharea.reduce((a, b) => a.filter(c => b.includes(c)));
        }
        if (cat1.length > 0) {
            cat1Unique = cat1.reduce((a, b) => a.filter(c => b.includes(c)));
        }
        if (cat2.length > 0) {
            cat2Unique = cat2.reduce((a, b) => a.filter(c => b.includes(c)));
        }
        let datata = []
        let datacat1 = []
        let datacat2 = []

        if (props?.allcategory?.length > 0 && thUnique?.length > 0) {
            for (let i = 0; i < props.allcategory.length; i++) {
                if (thUnique.includes(props.allcategory[i].id)) {
                    let list = props.allcategory[i]
                    list['therapeutic_area_id'] = props.allcategory[i].id
                    list['therapeutic_area_name'] = props.allcategory[i].name
                    datata.push(list)
                }
            }
            if (cat1Unique.length > 0) {
                for (let i = 0; i < datata.length; i++) {
                    if (thUnique.includes(datata[i].id)) {
                        for (let j = 0; j < datata[i].children.length; j++) {
                            let list = []
                            if (cat1Unique.includes(datata[i].children[j].id)) {
                                list['category_1_id'] = datata[i].children[j].id
                                list['category_1_name'] = datata[i].children[j].name
                                list['children'] = datata[i].children[j].children
                                list['id'] = datata[i].children[j].id
                                list['therapeutic_area_id'] = datata[i].therapeutic_area_id
                                datacat1.push(list)
                            }

                        }
                    }
                }
                if (cat2Unique.length > 0) {
                    for (let i = 0; i < datacat1.length; i++) {
                        if (cat1Unique.includes(datacat1[i].id)) {
                            for (let j = 0; j < datacat1[i].children.length; j++) {
                                let list = []

                                if (cat2Unique.includes(datacat1[i].children[j].id)) {
                                    list['category_2_id'] = datacat1[i].children[j].id
                                    list['category_2_name'] = datacat1[i].children[j].name
                                    list['category_1_id'] = datacat1[i].category_1_id
                                    datacat2.push(list)

                                }
                            }

                        }
                    }
                }
            }
        }
        let tree = GetAllCategoryTree(datata, datacat1, datacat2)
        SetCategoryTree(tree);
        let listtree = []
        for (let i = 0; i < tree.length; i++) {
            if (tree[i].children.length > 0) {
                listtree.push(tree[i]);
            }
        }
        SetCategoryTree(listtree);

    }, [props.affiliates, props.allcategory, props.affiliatesReload])


    const handleCategory2 = (event, id) => {
        if (event.target.checked) {
            props.SetSelectedSubCategory([...props.selectedSubCategory, id]);
        } else {
            let cat2List = props.selectedIndications.filter(item => item !== id)
            props.SetSelectedSubCategory(cat2List);
        }
    }
    function handleAccordianCheckoxValidation(value) {
    }
    const handlerLibraryCheckbox = (event, data) => {
        if (event.target.checked) {
            props.SetLibraries([...props.libraries, event.target.value])
        } else {
            var array = [...props.libraries];
            const filteredArray = array.filter((item) => {
                if (item === event.target.value) {
                    return false;
                }
                return true;
            });
            props.SetLibraries(filteredArray);
        }
    }
    React.useEffect(() => {
        let service = new ContentService();
        service.GetAllLibrary().then((data) => {
            SetallLibrary(data.data)
        })
        GetAllDetails();
    }, []);



    React.useEffect(() => {
        let list = []
        if (allLibrary.length > 0 && props.affiliates.length > 0) {
            for (let j = 0; j < props.affiliates.length; j++) {
                let key = [];
                let arr = [];
                key['name'] = props.affiliates[j].country_name
                if (props.affiliates[j].library_active === 3) {
                    for (let i = 0; i < allLibrary.length; i++) {
                        if (allLibrary[i].status) {
                            if (allLibrary[i].country_code === props.affiliates[j].country_code) {
                                const obj = { id: allLibrary[i].library_id, name: allLibrary[i].library_name }
                                arr.push(obj)
                            }
                            key['list'] = arr
                        }
                    }
                }
                list.push(key)
            }
            Setselectedlibrary(list)
        } else {
            Setselectedlibrary([])
        }

    }, [props.affiliates, allLibrary]);

    React.useEffect(() => {
    }, [props.selectedTherapeuticAreas, props.selectedBrands, props.selectedIndications, selectedlibrary])

    React.useEffect(() => {
    }, [props.taxBrandInd, allBrandIndications])

    React.useEffect(() => {
        if (!flagBrandIndication && props.id) {
            GetAllDetails();
        }
    }, [props.taxBrandInd]);

    const GetAllDetails = () => {
        let service = new ContentService();
        service.GetAllDetails().then((data) => {
            let jsondata = data.data;
            let unique = [...new Map(jsondata.map((item) => [item["ibu_brand_l1"], item])).values()];
            SetAllBrandIndications(jsondata)
            SetBrands(unique)
            if (props.taxBrandInd.length && jsondata.length) {
                let matchingItems = [];
                let matchingItemsIndication = []
                props.taxBrandInd.forEach(item1 => {
                    jsondata.forEach(item2 => {
                        if (item1.product_id === item2.product_group_id && item1.indication_id === item2.indication_id) {
                            matchingItems.push(item2)
                        }
                        if (item1.product_id === item2.product_group_id) {
                            matchingItemsIndication.push(item2)
                        }
                    })
                })
                let unique_brands = [];
                for (let i = 0; i < matchingItems.length; i++) {
                    let item = matchingItems[i];
                    if (!(unique_brands.some(x => x.product_group_id == item.product_group_id))) {
                        unique_brands = [...unique_brands, matchingItems[i]]
                    }
                }
                setSelectedBrands(unique_brands.map(x => ({ label: x.ibu_brand_l1, key: x.product_group_id })))
                props.setCheckBrand(unique_brands.map(x => ({ label: x.ibu_brand_l1, key: x.product_group_id })));
                let unique_indications = [];
                for (let i = 0; i < matchingItemsIndication.length; i++) {
                    let item = matchingItemsIndication[i];
                    if (!(unique_indications.some(x => x.indication_id == item.indication_id))) {
                        unique_indications = [...unique_indications, matchingItemsIndication[i]]
                    }
                }
                SetIndications(unique_indications)
                setSelectedIndications(matchingItems.map(x => ({ label: x.ibu_indication_l1, key: x.indication_id, product_id: x.product_group_id })))
                props.setCheckIndication(matchingItems.map(x => ({ label: x.ibu_indication_l1, key: x.indication_id, product_id: x.product_group_id })))
                setflagBrandIndication(true)
            }
        }).catch((err) => { });
    }

    const handleSelectedBrands = (value) => {
        setSelectedBrands(value)
        props.SetselectedBrands(value)
        props.setCheckBrand(value)
        const selectedValueLabel = value.map((e) => e.label)
        const allBrandIndicationsCopy = JSON.parse(JSON.stringify(allBrandIndications))
        const filtered = allBrandIndicationsCopy.filter((e) => selectedValueLabel.includes(e.ibu_brand_l1))
        SetIndications(filtered)
        const filrr = selectedIndications.filter((e) => (filtered.map((e) => e.product_group_id).includes(e.product_id)))
        setSelectedIndications(filrr)
        props.SetselectedIndications(filrr)
        props.setCheckIndication(filrr);
        const list = JSON.parse(JSON.stringify(props.taxBrandInd));
        const listJson = list.filter((e) => selectedValueLabel.includes(e.ibu_brand_l1))
        props.setTaxBrandInd(listJson)

    }

    const handleSelectedIndications = (value) => {
        setSelectedIndications(value)
        props.setCheckIndication(value);
        props.SetselectedIndications(value)
        props.setTaxBrandInd(value.map(item => ({
            product_id: item.product_id,
            indication_id: item.key,
        })))
    }

    return (
        <>
            <Grid container className="content-accordian">
                <Grid className='content-field header_taxanomy' item md={12} sm={12}>
                    <FormControl component="fieldset">
                        <label className='input-label mandatory'>
                            IBU Taxonomy Brand and Indications (Not visible for External Users)</label>
                    </FormControl>
                </Grid>
                <Grid className='content-field' item md={6} sm={12}>
                    <Multiselect
                        title={'Brand'}
                        disabled={!props.enableFields}
                        tags={selectedBrands}
                        open={true}
                        list={brands.length > 0 ? brands.map(x => ({ label: x.ibu_brand_l1, key: x.product_group_id })) : []}
                        handleSelected={(value) => { handleSelectedBrands(value) }} />
                </Grid>
                <Grid className='content-field' item md={6} sm={12}>
                    <Multiselect
                        title={'Indication'}
                        disabled={!props.enableFields}
                        tags={selectedIndications}
                        open={true}
                        list={indications.length > 0 ? indications.map(x => ({ label: x.ibu_indication_l1, key: x.indication_id, product_id: x.product_group_id })) : []}
                        handleSelected={(value) => { handleSelectedIndications(value) }} />
                </Grid>
                <Grid className='content-field' item md={12} sm={12}>
                    <label className='input-label mandatory'>
                        Therapeutic Areas, Category 1 and Category 2
                    </label>
                    <label className='helper-text'>
                        Category 1 is mandatory field
                    </label>
                    {categories.map((data, id) => (
                        <CustomAccordain subcategory
                            name={data.name} id={data.id} children={data.children}
                            disableCheckboxes={!props.enableFields}
                            selectedCat1={props.selectedCategory}
                            selectedCat2={props.selectedSubCategory}
                            handleCategory2={handleCategory2}
                            selectedTherapeuticAreas={props.selectedTherapeuticAreas}
                            SetselectedTherapeuticAreas={props.SetselectedTherapeuticAreas}
                            setSelectedCat1={props.SetSelectedCategory}
                            setSelectedCat2={props.SetSelectedSubCategory}
                            checkedItems={checkedItems}
                            setCheckedItems={setCheckedItems}
                            affiliates={props.affiliates} />
                    ))
                    }
                </Grid>
                <Grid className='content-field' item md={12} sm={12}>
                    <label className='input-label'>
                        Select Library
                    </label>
                    {selectedlibrary.length > 0 ? selectedlibrary.map((item, index) => (
                        <CustomAccordain
                            disabled={!item.list}
                            disableCheckboxes={!props.enableFields}
                            name={item.name}
                            id={item.id}
                            libraries={props.libraries}
                            SetLibraries={props.SetLibraries}
                            children={item.list}
                            handlecat1Change={handlerLibraryCheckbox}
                            selectedCat1={props.libraries}
                            affiliates={props.affiliates}
                        />
                    )) : ""}
                </Grid>
            </Grid>
        </>
    )
}

export default ContentCategory