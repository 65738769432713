/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//set transition for modal
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ setIsDisabled, modalFunc, sendDataToModal }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    modalFunc.current = handleClickOpen
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsDisabled(false)
  };

  return (
    <div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='dialogTitle' id="alert-dialog-slide-title">
          <IconButton className='closeBtn'
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialogContent'>
          <DialogContentText id="alert-dialog-slide-description">
            {sendDataToModal}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dialogActions'>
          <Button className='dialogCloseBtn' onClick={handleClose} color="primary">
            Close
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}